import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import SliderWeek3 from "./SliderWeek3";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import ReactPlayer from "react-player";
import Vimeo from "@u-wave/react-vimeo";
import Collapse from "../components/Collapse";
import Confetti from "react-confetti";
import "./new-certi.css";
import QuizComponent from "./QuizComponent";
import Quote from "../components/Quote";

import QuizComponent1 from "./QuizComponent1";

const Week4 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 4;
  const week_name = "week_3";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [checkBox, setCheckBox] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [publicspeakingExercise, setpublicspeakingExercise] = useState({
    reflection: "",
  });

  const arePublicspeakingValid =
    countWords(publicspeakingExercise?.reflection) >= 50;

  const isAnySectionInvalid = !arePublicspeakingValid;
  const [RecheckBox, setRecheckBox] = useState(false);
  const [RecheckBox2, setRecheckBox2] = useState(false);

  function countWords(text) {
    const trimmedText = text?.trim();
    const words = trimmedText?.split(/\s+/);
    return words?.length;
  }

  const questionsData2 = [
    {
      question:
        "In a team meeting, a disagreement arises about an important project decision. What do you do?",
      options: [
        "Stand firm on my viewpoint, especially if I think it's the best choice. (Competing)",
        "Discuss options openly and try to find a solution that satisfies everyone’s input. (Collaborating)",
        "Suggest a middle ground where each side compromises a bit. (Compromising)",
        "Let others take the lead and avoid taking sides. (Avoiding)",
        "Agree with the other person’s viewpoint to keep the peace. (Accommodating)",
      ],
    },

    {
      question:
        "A team member criticizes your recent work. How do you respond?",
      options: [
        "Defend my work and explain why I made those choices. (Competing)",
        "Ask for specific feedback and collaborate on ways to improve. (Collaborating)",
        "Acknowledge their perspective and suggest a change that works for both of us. (Compromising)",
        "Stay silent and move on, preferring to avoid conflict. (Avoiding)",
        "Apologize, even if I don’t fully agree, to avoid tension. (Accommodating)",
      ],
    },

    {
      question:
        "Two team members disagree, and the discussion becomes heated. You…",
      options: [
        "Step in and assert my opinion to help settle things. (Competing)",
        "Encourage both sides to share their thoughts and work on a joint solution. (Collaborating)",
        "Propose a compromise so each side gets part of what they want. (Compromising)",
        "Wait for the argument to settle on its own before stepping in. (Avoiding)",
        "Support one side to help end the conflict faster. (Accommodating)",
      ],
    },

    {
      question:
        "A last-minute task is assigned that may conflict with your schedule. You…",
      options: [
        "Say I can’t do it since it conflicts with my priorities. (Competing)",
        "Look for a way to rearrange my schedule or team up with someone to get it done. (Collaborating)",
        "Offer to do part of the work and ask someone else to cover the rest. (Compromising)",
        "Accept it quietly, even if it disrupts my plans. (Avoiding)",
        "Take on the task, putting my needs aside. (Accommodating)",
      ],
    },

    {
      question:
        "During a brainstorming session, your ideas are being overshadowed by others. How do you handle it?",
      options: [
        "Assertively pitch my ideas until they’re given fair consideration. (Competing)",
        "Listen to others and build upon their ideas, aiming for a collaborative solution. (Collaborating)",
        "Suggest combining ideas to balance everyone's input. (Compromising)",
        "Keep my ideas to myself if no one seems interested. (Avoiding)",
        "Support the ideas others propose to avoid causing issues. (Accommodating)",
      ],
    },
  ];

  const questionsData1 = [
    {
      question:
        "Scenario 1: As one of the choreographers in the productions department of DanceLUMS, Ali notices that one member, Ayesha, consistently misses rehearsals, impacting the whole team’s preparation. \n How should Ali respond?",
      options: [
        "Privately approach Ayesha to ask about her absence, expressing concern and understanding. If she’s facing difficulties, offer support or suggest ways she can catch up, like attending extra practice sessions.",
        "Briefly mention in the next group meeting that attendance is mandatory and should be improved, without calling Ayesha out directly.",
        "Ignore Ayesha’s absences, hoping she’ll improve on her own, or wait for another team member to bring it up.",
      ],
      correctAnswer:
        "Privately approach Ayesha to ask about her absence, expressing concern and understanding. If she’s facing difficulties, offer support or suggest ways she can catch up, like attending extra practice sessions.",
    },
    {
      question:
        "Scenario 2: As Engagement Lead in the LUMS Consultancy Group, Farah is working with her team to finalize a client presentation. A day before the presentation, the client requests major changes that would require significant overtime from the team. How should Farah respond?",
      options: [
        "Inform the team about the request, clearly explaining the changes needed and acknowledging the extra work required. Ask if anyone has conflicts with working overtime and work with those available to divide the tasks fairly, while ensuring she does her part as well.",
        "Notify only a few team members who she knows can work overtime and focus on the most essential changes requested by the client.",
        "Attempt to make all the changes herself without informing the team, staying up late to finish everything. If the quality suffers, she’ll explain to the client that they did their best with limited time.",
      ],
      correctAnswer:
        "Inform the team about the request, clearly explaining the changes needed and acknowledging the extra work required. Ask if anyone has conflicts with working overtime and work with those available to divide the tasks fairly, while ensuring she does her part as well.",
    },
    {
      question:
        "Scenario 3: As Marketing Director for LCSS, Bilal secures a sponsor for an upcoming charity event. The sponsor promises funds but has repeatedly delayed payment. It has been 3 weeks since the event concluded, and the sponsor’s payment is still pending. How should Bilal respond?",
      options: [
        "Inform the president about the situation and discuss potential backup plans. Bilal should contact the sponsor firmly but professionally, explaining the urgency and asking for a clear payment timeline.",
        "Only follow up with the sponsor himself without involving the rest of the team. If the payment doesn’t come through, he’ll inform the president right before the event.",
        "Do nothing, hoping the funds will come in at the last minute. If they don’t, he’ll tell the team it was the sponsor’s fault.",
      ],
      correctAnswer:
        "Inform the president about the situation and discuss potential backup plans. Bilal should contact the sponsor firmly but professionally, explaining the urgency and asking for a clear payment timeline.",
    },
    {
      question:
        "Scenario 4: As Vice President of Dramaline, Sana is finalising auditioned roles for an upcoming play. Ali, a new member, wanted a lead role but wasn’t chosen. He’s upset, believing he was unfairly overlooked. How should Sana respond?",
      options: [
        "Privately meet with Ali to explain the selection process and the reasons behind role assignments, acknowledging his interest and suggesting ways he can build experience for future roles. Offer to keep him involved in other aspects to develop his skills.",
        "Acknowledge Ali’s disappointment but explain briefly in a group meeting that role selection was based on experience and the needs of the play.",
        "Ignore Ali’s disappointment and hope he’ll get over it with time, avoiding a potentially awkward conversation.",
      ],
      correctAnswer:
        "Privately meet with Ali to explain the selection process and the reasons behind role assignments, acknowledging his interest and suggesting ways he can build experience for future roles. Offer to keep him involved in other aspects to develop his skills.",
    },
    {
      question:
        "Scenario 5: As the President of the Music Society, Zoya has been approached by multiple members asking to collaborate on individual passion projects. While these are exciting opportunities, they conflict with the society’s planned events, and the team feels stretched thin. How should Zoya respond?",
      options: [
        "Privately discuss with members the need to prioritize society events while acknowledging their enthusiasm. Suggest creating a separate time or sub-group for passion projects that don’t interfere with the main calendar.",
        "Announce in a team meeting that focus needs to remain on society events. Allow passion projects but warn members that they must manage their time independently.",
        "Agree to all passion projects without evaluating the workload or resources, trusting the team to figure things out.",
      ],
      correctAnswer:
        "Privately discuss with members the need to prioritize society events while acknowledging their enthusiasm. Suggest creating a separate time or sub-group for passion projects that don’t interfere with the main calendar.",
    },
  ];

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    strengths: "",
    weaknesses: "",
    opportunities: "",
    threats: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        // toast.success("Good Job! Activity Submitted", {
        //   icon: "👏",
        //   style: {
        //     borderRadius: "55px",
        //     marginBottom: "15px",
        //   },
        // });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            strengths: data.strengths,
            weaknesses: data.weaknesses,
            opportunities: data.opportunities,
            threats: data.threats,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway,
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          // toast.success("Good Job! Activity Submitted", {
          //   icon: "👏",
          //   style: {
          //     borderRadius: "55px",
          //     marginBottom: "15px",
          //   },
          // });
          setpublicspeakingExercise({
            reflection: data.reflection,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,
          config,
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 7, pathway_id);
      // fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "898992480"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736582443/Slide_16_9_-_103_p6hmpz.png", // Replace with your image URL
    "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736582443/Slide_16_9_-_104_ooyaw3.png", // Replace with your image URL
  ];

  const handleNextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_4",
              },
              config,
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
      setIsOptionSelected(false);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active") {
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: publicspeakingExercise,
            pathway: 1,
            status: "Reviewing",
            rating: 0,
            pr_enabled: true,
            activity_name: "Reflecting on Communication in Leadership",
          },
          config,
        )
        .then((response) => {
          // Handle success
          setDisableBtn(true);
          setIsSubmitted(true);
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        })
        .catch((error) => {
          // Handle error
          console.error("Error submitting activity:", error);
          toast.error("Error submitting activity. Please try again later.", {
            icon: "❌",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
        });
    }
  };

  const accordionData = [
    {
      title: "Clarity",
      content:
        "Your message needs to be clear and precise. Avoid using jargon or complex language that could lead to misunderstandings.",
    },
    {
      title: "Listening",
      content:
        "Effective communication is a two-way street. Listening to your team members fosters a sense of respect and openness, creating an environment where everyone feels heard and valued.",
    },
    {
      title: "Empathy",
      content:
        "Understanding and acknowledging the feelings and perspectives of others strengthens relationships and promotes effective collaboration. Cultivating empathy in your communication fosters a supportive and inclusive team culture.",
    },
    {
      title: "Non-Verbal Communication",
      content:
        "Your body language, facial expressions, and tone of voice can convey more than words. Being aware of your non-verbal cues can significantly enhance your communication effectiveness, ensuring that your intended message aligns with your non-verbal signals.",
    },
    {
      title: "Feedback",
      content:
        "Offering constructive feedback helps your team members grow and improve. Similarly, being open to feedback allows you to reflect and improve as a leader. Creating a feedback-rich environment promotes continuous learning and development.",
    },
    {
      title: "Adaptability",
      content:
        "Different situations and people require different communication styles. Being adaptable in your communication approach is key to effective leadership.",
    },
  ];

  const accordionData2 = [
    {
      title: "Deeper Than Words",
      content:
        "Communication is not just about relaying information; it's about making a connection. This is achieved when your message resonates with your audience's experiences, emotions, and values. Remember, as a leader, your goal is not just to be heard, but to be understood and to evoke a response.",
    },
    {
      title: "Setting Intentions",
      content:
        "Just like Karim, set clear intentions before communicating. In this case, Karim's intention was to ensure his team's arguments not only made sense logically but also emotionally connected with the audience. When you communicate with a clear purpose, your message becomes more impactful.",
    },
    {
      title: "The Power of Why",
      content:
        "Karim encouraged his team to focus on the 'why' of their arguments. The 'why' is the emotional core of your message and the key to meaningful communication. As Simon Sinek famously puts it, \"People don't buy what you do, they buy why you do it.\"",
    },
    {
      title: "Personalizing the Message",
      content:
        "By connecting their arguments to broader themes, Karim's team was able to create a narrative that resonated with their audience. As leaders, it's important to understand what matters to your audience and to tailor your message to those values and experiences.",
    },
    {
      title: "Emotion is Key",
      content:
        "Emotion is a powerful tool in communication. It's what transforms a good argument into a compelling narrative. As a leader, your ability to infuse passion and conviction into your message will set you apart.",
    },
  ];

  const accordionData3 = [
    {
      title: "Effective Communication",
      content:
        "This week, we delved into the essence of effective communication and its pivotal role in impactful leadership. We studied how clarity, empathy, active listening, and non-verbal cues combine to create powerful communication channels, and we learned this through Karim's continued leadership journey.",
    },
    {
      title: "Drivers of Effective Communication",
      content:
        "We explored the elements of effective communication, including the importance of giving and receiving meaningful messages, the power of non-verbal communication, and the need for adaptable communication styles. Recognizing and understanding these elements are vital for building strong communication skills.",
    },
    {
      title: "Importance of Meaning in Communication",
      content:
        "We delved into why it's crucial to infuse our communications with meaning and purpose. We explored this through a narrative that showed how Karim learned to craft meaningful messages that resonated with his audience and made him a more effective leader.",
    },
    // {
    //   title: 'Peer Review',
    //   content: "You provided valuable feedback to your peers on their speeches and discussion contributions. Your thoughtful insights and constructive criticism contributed to a supportive learning environment and helped your peers in their journey of improving their communication skills."
    // },
  ];

  const totalSteps = 8; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  const questionsData = [
    {
      question: "What is the role of effective communication in leadership?",
      options: [
        "To inform the team about tasks",
        "To convey expectations and give instructions",
        "To connect, inspire, and foster a shared understanding",
        "To command and control the team",
      ],
      correctAnswer: "To connect, inspire, and foster a shared understanding",
      explanation:
        "While A, B, and D may be parts of communication, the broader role of effective communication in leadership is to connect with the team, inspire them towards shared goals, and foster a mutual understanding (Option C).",
    },
    {
      question:
        "Which of the following is NOT a common challenge in effective communication?",
      options: [
        "Overlooking non-verbal communication",
        "Listening more than talking",
        "Misunderstanding communication as just talking",
        "Not adapting communication style to suit different situations",
      ],
      correctAnswer: " Listening more than talking",
      explanation:
        "Active listening is a crucial aspect of effective communication. Therefore, listening more than talking is not a challenge but rather a positive practice in effective communication (Option B).",
    },
    {
      question:
        "How does setting clear intentions before communicating help a leader?",
      options: [
        "It ensures that the leader dominates the conversation",
        " It ensures that the message is impactful and purposeful",
        "It ensures that the leader talks more than others",
        "It ensures that the leader does not have to listen to others",
      ],
      correctAnswer: " It ensures that the message is impactful and purposeful",
      explanation:
        "Clear intentions help the leader guide the communication in a purposeful and meaningful way, ensuring their message carries the necessary impact (Option B).",
    },
    {
      question: "What role does 'why' play in effective communication?",
      options: [
        "It complicates the message",
        " It adds unnecessary details",
        "It provides the emotional core of the message",
        "It diverts attention from the main message",
      ],
      correctAnswer: "It provides the emotional core of the message",
      explanation:
        "The 'why' in communication refers to the purpose, motivation, or reason behind a message or action. It provides the emotional core that resonates with people and makes the message more meaningful (Option C).",
    },
    {
      question:
        "According to Karim's story, what transformed the debate team's arguments into compelling narratives?",
      options: [
        "They used complicated jargon",
        " They spoke louder than their opponents",
        "They focused on personal connections and broader themes",
        "They rehearsed more times than their opponents",
      ],
      correctAnswer: "They focused on personal connections and broader themes",
      explanation:
        "In Karim's story, the debate team's arguments became compelling narratives when they connected their arguments to broader themes and personalized them. This made the arguments emotionally resonate with the audience (Option C).",
    },
  ];

  const books = [
    "Crucial Conversations: Tools for Talking when Stakes Are High by Kerry Patterson, Chapter 7 – How to Transform Anger and Hurt Feelings into Powerful Dialogue",
    "Business Communication: Developing Leaders for a Networked World by Peter Cardon, Chapter 4 – Crafting Messages for Electronic Media",
    "Just Listen by Mark Goulston, Chapter 4 – The Power of Listening",
    "Interpersonal Communication by Sarah Trenholm and Arthur Jensen, Chapter 7 – The Skills of Listening",
    "10 Ways to Have a Better Conversation by Celeste Headlee",
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  // const handleNextQuestionOrFinish = () => {
  //   if (currentQuestion + 1 < questionsData.length) {
  //     setCurrentQuestion(currentQuestion + 4);
  //     setSelectedOption('');
  //     setShowExplanation(false);
  //   }
  // };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
      console.log("Current Question Index:", currentQuestion + 1); // Debugging log
    }
  };

  const [nextBtnCondtion, setNextBtnCondtion] = useState(false);

  useEffect(() => {
    const wordCounts = Object.values(publicspeakingExercise).map(
      (value) => value.split(/\s+/).filter((word) => word !== "").length,
    );
    // alert("WORDS: "+wordCounts);
    if (
      currentStep === 0 ||
      currentStep === 1 ||
      currentStep === 2 ||
      currentStep === 3 ||
      currentStep === 4 ||
      currentStep === 5 ||
      currentStep === 6
    ) {
      setNextBtnCondtion(true); // Enable button at step 0
    } else if (currentStep === 2 && currentImageIndex < 6) {
      setNextBtnCondtion(false);
    } else if (currentStep === 2 && currentImageIndex > 5) {
      setNextBtnCondtion(true);
    } else if (currentStep === 3 && status === "Completed") {
      setNextBtnCondtion(true);
    }

    // else if (currentStep === 7 && status==='Active' && Object.values(publicspeakingExercise).every(value => value !== '')){
    //   setNextBtnCondtion(true);
    // }
    else if (
      currentStep === 3 &&
      status === "Completed" &&
      wordCounts > 50 &&
      disableBtn
    ) {
      setNextBtnCondtion(true);
    }

    // else if (currentStep === 5 && ((status==='Completed' && Object.values(publicspeakingExercise).some(value => value !== '')))) {
    //   setNextBtnCondtion(true);
    // }
    else {
      setNextBtnCondtion(false);
    }
  }, [
    currentStep,
    currentImageIndex,
    status,
    publicspeakingExercise,
    isAnySectionInvalid,
    disableBtn,
  ]);

  useEffect(() => {
    // Check if images are already in localStorage
    const storedImages = localStorage.getItem("week3Images");

    if (!storedImages) {
      // If not in localStorage, fetch the images
      const slideImages = [
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736498983/Slide_16_9_-_99_qm9ts3.png",
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736498983/Slide_16_9_-_100_n6n1zo.png",
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736498983/Slide_16_9_-_101_lojpqg.png",
        "https://res.cloudinary.com/dmv23kuoc/image/upload/v1736498983/Slide_16_9_-_102_aq3zjb.png",
        // Add other image URLs as needed
      ];

      // Store the images in localStorage
      localStorage.setItem("week3Images", JSON.stringify(slideImages));
    }
  }, []);

  const handleQuizComplete = (completed) => {
    console.log("Quiz completed!", completed);
    // Add any completion logic here, e.g., enable next button
    handleNext(); // Proceed to next section after quiz
  };

  const steps = [
    // Define the content and structure of each step
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-semibold sm:text-xl">
        Welcome to Week 4: Conflict Management and Ethical Leadership
      </h2>
      <p className="mt-4 text-gray-600">
        Conflict is inevitable in any leadership role, but this week is all
        about turning challenges into opportunities. We’ll discuss strategies to
        manage conflicts within your team and external stakeholders while
        staying grounded in ethical decision-making. Through scenario-based
        activities, you’ll develop the skills to lead with integrity,
        transparency, and accountability.
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <Quote
            content={
              "Conflicts in student societies often arise due to unclear roles; defining responsibilities reduces misunderstandings."
            }
            person={""}
          />

          <div className="video-container">
            {/* <YouTube videoId={videoId} opts={opts} /> */}

            {/* <div ref={playerRef}></div> */}
            {/* <Vimeo
              video={videoId}
              autoplay
            /> */}
            <div className="flex justify-center items-center">
              <img
                clqssName="w-11/12 mx-auto"
                src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1736496741/rafiki_e5zhmm.png"
              />
            </div>
          </div>
        </div>
      </div>
     </section>,

    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-semibold sm:text-xl">
        Introduction to Conflict Resolution
      </h2>

      <div className="bg-gray-50 rounded-lg p-8 text-center border-2 border-dashed border-gray-200 mb-8">
        <p className="text-gray-500 italic">Video Coming Soon </p>
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 1:</b> Conflict Management Style Quiz
      </h2>
      <p className="mt-4 text-gray-600">
        Read each question and choose the answer that best describes how you
        would respond in each scenario.{" "}
      </p>

      <QuizComponent1
        questionsData={questionsData2}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
        onComplete={handleQuizComplete}
      />
    </section>,

    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      <h2 className="text-xl font-medium sm:text-xl mb-6">
        Your Conflict Management Style Summary
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
          <h3 className="font-semibold text-gray-800 mb-2">Competing:</h3>
          <p className="text-gray-600">
            You value assertiveness and are decisive in conflicts. This works
            well for quick decisions but might need balancing with listening.
          </p>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
          <h3 className="font-semibold text-gray-800 mb-2">Collaborating:</h3>
          <p className="text-gray-600">
            You prioritize teamwork and solutions that satisfy all. This often
            results in win-win outcomes but can be time-intensive.
          </p>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
          <h3 className="font-semibold text-gray-800 mb-2">Compromising:</h3>
          <p className="text-gray-600">
            You find middle ground solutions that partially satisfy everyone.
            Practical but might sometimes lack commitment.
          </p>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
          <h3 className="font-semibold text-gray-800 mb-2">Avoiding:</h3>
          <p className="text-gray-600">
            You tend to steer clear of conflict, which can defuse tension, but
            important issues may remain unresolved.
          </p>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
          <h3 className="font-semibold text-gray-800 mb-2">Accommodating:</h3>
          <p className="text-gray-600">
            You value harmony and often put others first, fostering goodwill but
            potentially overlooking your own needs.
          </p>
        </div>
      </div>

      <div className="mt-8 p-4 bg-purple-50 rounded-lg border-l-4 border-purple-400">
        <p className="text-purple-800">
          💡 Remember: These styles are situational - the most effective leaders
          adapt their approach based on context.
        </p>
      </div>
    </section>,

    // Ethical Leadership Video Section
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      <h2 className="text-xl font-medium sm:text-xl mb-6">
        Becoming an Ethical Leader: Transparency & Accountability
      </h2>

      {/* Video Placeholder */}
      <div className="bg-gray-50 rounded-lg p-8 text-center border-2 border-dashed border-gray-200 mb-8">
        <p className="text-gray-500 italic">
          Video Coming Soon - "Budget Blunders: A Lesson in Ethical Leadership"
        </p>
      </div>
    </section>,

    <section className="bg-white contMain flex items-start flex-row gpWindow rounded-lg mt-6 p-8">
      <section
        className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
        style={{ height: "100%" }}
      >
        <h2 className="text-xl font-medium sm:text-xl">
          <b>Title: Defining Key Values </b>
        </h2>
        <div className="mt-6 flex justify-start w-full">
          <SliderWeek3
            className="w-full"
            onImageIndexChange={handleImageIndexChange}
          />
        </div>
      </section>
      ,
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 2:</b> Scenarios Based Exercise
      </h2>

      <QuizComponent
        questionsData={questionsData1}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 3:</b> Giving and Receiving Constructive Feedback
      </h2>

      <div className="mt-4">
        <div className="image-gallery flex justify-center items-center">
          <img
            src={images[currentImage]}
            alt={`Gallery Image ${currentImage + 1}`}
            className="rounded-lg shadow-lg"
            style={{ maxHeight: "800px", maxWidth: "1000px" }}
          />
        </div>
        <div className="mt-4 flex justify-center">
          <button
            onClick={handleNextImage}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition"
          >
            Next
          </button>
        </div>
      </div>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 3:</b> Assessment
      </h2>

      <h2 class="text-lg font-bold">Instructions:</h2>
      <p class="mt-2">
        Carefully read the provided scenario and imagine you’re giving feedback
        to the person involved.
      </p>
      <p class="mt-2">
        Make sure you use the SBI Model to frame your feedback.
      </p>
      <p class="mt-2">
        Points will be awarded if you exceed the 150-word criteria for an
        impactful feedback response.
      </p>
      <p class="mt-2 font-semibold">Best of luck!</p>

      <h3 class="mt-4 text-lg font-bold">Prompt:</h3>
      <p class="mt-2">
        During a fundraiser planning meeting, Ali made several jokes that some
        team members found inappropriate, which affected the meeting’s focus and
        energy. How would you give Ali feedback on creating a more respectful
        and professional environment in future meetings?
      </p>

      <div className="mt-6 flex justify-start w-full">
        <div className="form-control w-full ">
          <textarea
            value={swotSubmission.response ?? ""}
            onChange={(e) => {
              const newValue = e.target.value;
              setSubmission((prev) => ({
                ...prev,
                response: newValue,
              }));
              const wordCount = countWords(newValue);
              setDisableBtn(wordCount > 5);
            }}
            className="textarea textarea-sm textarea-bordered h-20"
            placeholder="Type here..."
          ></textarea>
        </div>
      </div>

      <button
        className="btn btn-primary mt-8 bg-neutral-50"
        onClick={handleStepSubmission}
        disabled={status === "Completed" || !disableBtn}
      >
        Submit
      </button>
    </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
         return "Introduction to Conflict Resolution";

     case 2:
        return "individual Conflict Style Quiz"

      case 3:
        return "Conflict Style Quiz Summary";

      case 4:
        return "Becoming an Ethical Leader";

      case 5:
        return "Key Values of Ethical Leaders";

      case 6:
        return "Scenario Based Exercise";

      case 7:
        return "Giving and Receiving Constructive Feedback";

      case 8:
        return "Reflecting on Communication in Leadership";

      default:
        return "Self-Assessment";
    }
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              // disabled={!nextBtnCondtion}
                              disabled={
                                [2,6].includes(currentStep) && !isOptionSelected
                              }
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                              disabled={!isSubmitted}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default Week4;
