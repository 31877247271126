import React, { useState } from "react";

const QuizComponent = ({ questionsData,onOptionSelected  }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    // Notify parent that an option is selected
  };

  const handleShowExplanation = () => {
    setShowExplanation(true); // Show explanation when "Show Answer" is pressed
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
     
      setSelectedOption(null);
      setShowExplanation(false); // Reset explanation for the next question
    } else {
      console.log("Quiz Completed!");
      onOptionSelected(true);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-4 rounded-md">
      {currentQuestion < questionsData.length ? (
        <div>
          <h2 className="text-xl text-center font-semibold mb-8">
            {questionsData[currentQuestion].question}
          </h2>
          <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
            {questionsData[currentQuestion].options.map((option, index) => (
              <div
                key={index}
                className={`${
                  showExplanation &&
                  option.trim() ===
                    questionsData[currentQuestion].correctAnswer.trim()
                    ? "bg-green-500 text-white"
                    : selectedOption === option
                    ? "bg-purple-200"
                    : "bg-white"
                } p-4 rounded-2xl m-1 text-center border-2 cursor-pointer`}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
          {selectedOption && !showExplanation && (
            <div className="flex justify-center">
              <button
                className="btn btn-outline-primary mt-4"
                onClick={handleShowExplanation}
              >
                Show Answer
              </button>
            </div>
          )}
          {showExplanation && (
            <div className="mt-4">
              <p className="font-semibold">Correct Answer:</p>
              <p className="text-green-500">
                {questionsData[currentQuestion].correctAnswer}
              </p>

              {questionsData[currentQuestion].explanation && (
                <p className="mt-2 text-gray-700">
                  <strong>Explanation: </strong>
                </p>
              )}
              <p className="text-gray-700">
                {questionsData[currentQuestion].explanation}
              </p>
              <div className="flex justify-center mt-4">
                <button
                  className="btn btn-outline-primary"
                  onClick={handleNextQuestionOrFinish}
                >
                  {currentQuestion + 1 < questionsData.length
                    ? "Next Question"
                    : "Finish Quiz"}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
        </div>
      )}
    </div>
  );
};

export default QuizComponent;
