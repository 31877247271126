// GalleryPage.js
import React from "react";
import ImageSlider from "./ImageSlider"; // Import the ImageSlider component

const SliderWeek7 = ({ onImageIndexChange }) => {
  const slideImages = [
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1737448887/Slide_16_9_-_114_amhh0d.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1737448887/Slide_16_9_-_115_irzqac.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1737448887/Slide_16_9_-_116_oys9nm.png",
  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };

  return (
    <div className="gallery-page">
      <ImageSlider
        className="w-full"
        onImageIndexChange={handleImageIndexChange}
        images={slideImages}
      />{" "}
      {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek7;
