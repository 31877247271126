import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";

import Navbar from "../components/Navbar";
import SideInnerSM from "../components/SideInnerSM";
import PassWarn from "./PassWarn";
import SoceityAdd from "./SoceityAdd";
// import SoceityAdd from "./SocietyAdd";

const SplashGP = () => {
  let { user } = useContext(AuthContext);

  // extract profile id
  let params = useParams();
  let profileID = user["user_id"];

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [isAuthorized, setisAuthorized] = useState(false);

  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,config);
  //       let data = await response.json();
  //       setProfile(data);
  //       setLoading(false);

  //       if (profile?.status === 1 || profile?.status === "1") {
  //         setisAuthorized(true);
  //       }
  //     } catch (err) {
  //       console.log("The requested profile does not exist.");
  //       setLoading(false); // Set loading to false even on error to stop showing the loader
  //     }
  //   };

  //   // Simulate the loader by setting a timer for 3 seconds
  //   const timer = setTimeout(() => {
  //     fetchData();
  //   }, 900);

  //   // Clean up the timer when the component unmounts
  //   return () => clearTimeout(timer);
  // }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  console.log("STATUS: " + profile?.status);

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            {/* <Sidebar /> */}

            {/* {isSidebarVisible && <SideInnerSM/>} */}

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              {/* Navbar */}
              <Navbar toggleSidebar={toggleSidebar} />

              {(() => {
                if (profile?.first_login === true) {
                  return <PassWarn profile={profile} />;
                } else if (
                  profile?.first_login === false &&
                  profile?.society_popup === false
                ) {
                  return <SoceityAdd profile={profile} />;
                }
              })()}
              {/* end Navbar */}
              {/* cards */}
              <div className="w-full px-6 py-6 mx-auto">
                {loading ? (
                  <div className="flex items-center justify-center h-screen space-x-2">
                    <div className="w-4 h-4 rounded-full animate-pulse bg-violet-600"></div>
                    <div className="w-4 h-4 rounded-full animate-pulse bg-violet-600"></div>
                    <div className="w-4 h-4 rounded-full animate-pulse bg-violet-600"></div>
                  </div>
                ) : (
                  <div>
                    <section className="bg-white rounded-lg mt-6">
                      <div className="mx-auto max-w-screen-xl px-12 py-8 sm:py-12 sm:px-12 lg:py-16 lg:px-12 rounded-lg splashBanner_GP">
                        <div className="">
                          

                          <div className="py-8">

                            <div className="flex flex-col md:flex-row justify-center items-center">
                            <div className=" w-full md:w-1/2 relative overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full flex justify-center">
                            <img
                              alt="Party"
                              src="https://res.cloudinary.com/ahbranding/image/upload/v1692444506/AHbranding/Group_34784401_ze3vxb.png"
                              className="h-auto w-full object-cover self-center"
                            />
                              </div>
                            
                             <div className="w-full md:w-1/2">

                           
                             <span className="border-2 rounded-full py-2 px-4 mb-4 flex w-max ">
                              {" "}
                              Hey <span className="wave">👋</span>{" "}
                             </span>
                             <h2 className="text-3xl font-medium sm:text-4xl">
                              Welcome to
                             </h2>
                             <h2 className="text-3xl font-bold text-primary sm:text-4xl">
                              Ascent Leadership Program
                             </h2>
                             <p className="mt-4 text-gray-600">
                              10-weeks journey designed to elevate the
                              leadership potential of Pakistan's brightest
                              minds. This innovative program uniquely combines
                              academic rigor with practical skill-building in
                              key areas like emotional intelligence,
                              communication, strategic thinking, and more.
                              Tailored for today's ambitious learners, each week
                              focuses on a specific leadership competency,
                              enriched with interactive activities and
                              insightful resources. As we embark on this
                              transformative path, we aim to equip you with the
                              essential skills to lead effectively in diverse
                              scenarios and contribute significantly to societal
                              progress.
                             </p>
                             </div>
                             </div>
                            <div className="mt-12 p-6 bg-gray-50 border border-gray-300 rounded-lg">
                              <h2 className="text-xl font-bold mb-4">
                                Credits & Acknowledgments
                              </h2>
                              <p className="mb-4">
                                This project was made possible through the
                                dedication and collaboration of individuals and
                                teams committed to enhancing student
                                experiences. We extend our sincere gratitude to
                                everyone who contributed to the success of
                                Ascent.
                              </p>

                              <h3 className="text-lg font-semibold">
                                Project Supervisors
                              </h3>
                              <p>Ashar Javaid & Eilya Mohsin</p>

                              <h3 className="text-lg font-semibold mt-4">
                                Project Lead
                              </h3>
                              <p>Ayesha Arshad</p>

                              <h3 className="text-lg font-semibold mt-4">
                                Student Partner
                              </h3>
                              <p>Irza Farid</p>

                              <h3 className="text-lg font-semibold mt-6">
                                Special Thanks
                              </h3>
                              <ul className="list-disc list-inside ml-4">
                                <li>Omar Rehman</li>
                                <li>
                                  Office of Accessibility and Inclusion (OAI)
                                </li>
                                <li>
                                  Counselling & Psychological Services (CAPS)
                                </li>
                              </ul>

                              <h3 className="text-lg font-semibold mt-6">
                                Platform Development
                              </h3>
                              <p>
                                Developed and powered by{" "}
                                <strong>Startup Early</strong>.
                              </p>
                            </div>
                            <Link to={`/weeks`}>
                              <button className="btn btn-primary mt-8 gap-2">
                                {" "}
                                Get Started
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                                  />
                                </svg>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                )}
              </div>
            </main>
          </>
        </div>
      </div>
    </div>
  );
};

export default SplashGP;
