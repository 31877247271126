import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Collapse from "../components/Collapse";
import Vimeo from "@u-wave/react-vimeo";
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import SliderWeek9 from "./SliderWeek9";
import QuizComponent from "./QuizComponent";
import Quote from "../components/Quote";

const Week10 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 10;
  const week_name = "week_9";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway,
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config,
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`,
          config,
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_10",
              },
              config,
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
      setIsOptionSelected(false);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(
          `${process.env.REACT_APP_API_KEY}/api/peer_feedback/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: 4,
            content: swotSubmission,
            pathway: 1,
            feedback: swotFeedback,
            rating: 0,
          },
          config,
        );
        toast.success("Good Job! Feedback Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        return;
      } else {
        axios.post(
          `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: swotSubmission,
            pathway: 1,
            status: "Reviewing",
            rating: 0,
            pr_enabled: true,
            activity_name: " Entering the E-Commerce Market in Pakistan",
          },
          config,
        );
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      }
    }
  };

  const totalSteps = 6; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };

  const questionsData = [
    {
      question:
        "Scenario planning in student societies is only about predicting and preparing for risks.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question:
        "Engaging members in scenario planning can limit the society's ability to forecast effectively.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question:
        "Flexibility in strategy is unnecessary if the initial plan is well-thought-out and robust.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question:
        "Understanding and applying forecasting tools enhances the ability to anticipate future trends and challenges.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },
    {
      question:
        "Scenario planning should only focus on the most likely future scenario, ignoring less probable outcomes.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
  ];

  const accordionData = [
    {
      title: "Cultivating a Vision for LES",
      content:
        "Problem:\nLES faces stagnation, needing fresh vision and direction.\n\nShameer’s Approach:\nHe initiates a vision-setting exercise, encouraging members to envision future possibilities for LES. This fosters a shared vision and aligns the team's efforts towards common long-term objectives.\n\n",
    },
    {
      title: "Adapting to Market Trends",
      content:
        "Problem:\nRapid changes in the entrepreneurial landscape present new challenges and opportunities for LES.\n\nShameer’s Approach:\nHe emphasizes the importance of staying informed and adaptable. Together with his team, he analyzes market trends and adjusts LES's events' marketing accordingly, showcasing strategic thinking in a dynamic environment.\n\n",
    },
    {
      title: "Leveraging Team Diversity",
      content:
        "Problem:\nLES comprises members with diverse skills and entrepreneurial interests.\n\nShameer’s Approach:\nRecognizing the power of diverse perspectives, Shameer strategically aligns team members with initiatives that suit their strengths and interests, thereby maximizing the society's potential and fostering innovation.\n\n",
    },
    {
      title: "Balancing Growth and Sustainability",
      content:
        "Problem:\nLES seeks to expand its reach while ensuring its initiatives are sustainable.\n\nShameer’s Approach:\nHe adopts a balanced approach, carefully planning growth initiatives while considering their long-term sustainability and impact.\n",
    },
  ];

  const accordionData2 = [
    {
      title: "Focus on the Principles",
      content:
        "Consider the broader impact of your actions by framing your decisions around ethical principles rather than short-term gains. Research indicates that leaders who prioritize ethical standards over immediate results cultivate a more loyal, trusting, and high-performing team.",
    },
    {
      title: "Distinguishing Ethics from Morals",
      content:
        "Ground your leadership approach in a clear understanding of both ethics and morals. While morals often stem from individual beliefs and cultural backgrounds, ethics typically refer to the accepted standards of behavior in professional and public domains. Appreciating this distinction can enhance your ability to navigate complex situations with clarity and confidence, creating an environment where ethical considerations guide the way to sustainable success.",
    },
  ];

  const accordionData3 = [
    {
      title: "Vision for the Future",
      content:
        "A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.",
    },
    {
      title: "Ethical Foundations",
      content:
        "Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.",
    },
    {
      title: "Environmental Stewardship",
      content:
        "Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization's identity and mission.",
    },
    {
      title: "Community Enrichment",
      content:
        "Sustainable leadership involves a commitment to the well-being of the community. Leaders invest in social initiatives, support educational programs, and engage in partnerships that address social issues. Their legacy is marked by the positive impact on the community’s health, wealth, and social fabric.",
    },
  ];

  const accordionData82 = [
    {
      title: "Cultivate a Shared Vision",
      content:
        "- Establishing a shared vision is fundamental for aligning team efforts towards common goals.\n" +
        "Reflect on your recent team interactions:\n" +
        "- How have I communicated and fostered a shared vision within the team?\n" +
        "- In what ways have I ensured that everyone understands and is committed to our collective goals?\n" +
        "- What strategies can I employ to reinforce our vision and keep the team motivated and focused on long-term objectives?",
    },
    {
      title: "Analyse and Set SMART Goals",
      content:
        "- Effective goal setting is a collaborative and strategic process. Evaluate your team's approach to setting goals:\n" +
        "- How do we define and agree upon our goals? Are they Specific, Measurable, Achievable, Relevant, and Time-bound (SMART)?\n" +
        "- Where can we improve in making our goals more aligned with our strategic vision?\n" +
        "- How can I lead the team to break down our larger vision into achievable, strategically informed goals?",
    },
    {
      title: "Encourage Participation in Vision and Goal Setting",
      content:
        "- Active participation in vision and goal setting is key to team buy-in and commitment. Assess your role in facilitating this involvement:\n" +
        "- Have I created opportunities for every team member to contribute to our vision and goal-setting process?\n" +
        "- How can I better ensure that all voices are heard and considered in shaping our team's direction?\n" +
        "- In what ways can I empower each member to take ownership of parts of our vision and goals, thereby strengthening their commitment and the team's cohesion?",
    },
  ];

  const accordionData4 = [
    {
      title: "Developing a Structured Research Approach",
      content:
        "- Structuring your research is vital for effective information gathering.\n" +
        "- Reflect on your recent research tasks:\n" +
        "  - How do I define my research objectives?\n" +
        "  - What strategies am I using to collect and organize information?\n" +
        "  - How do I ensure the sources I use are credible and relevant?",
    },
    {
      title: "Analysing Information and Drawing Insights",
      content:
        "- The essence of research lies in analyzing information and extracting valuable insights.\n" +
        "- Assess your recent research endeavors:\n" +
        "  - How do I analyze the data and information I gather?\n" +
        "  - In what ways do I synthesize information to draw meaningful conclusions?\n" +
        "  - How do I ensure my analysis is unbiased and objective?",
    },
    {
      title: "Incorporating Diverse Perspectives and Feedback",
      content:
        "- Including diverse perspectives enhances the depth and breadth of your research.\n" +
        "- Evaluate your approach to incorporating different viewpoints:\n" +
        "  - Have I considered alternative perspectives in my research?\n" +
        "  - How do I seek and incorporate feedback to refine my research?\n" +
        "  - What steps do I take to challenge my assumptions and broaden my understanding?",
    },
  ];

  const accordionData5 = [
    {
      title: "Fundamentals of Financial Statements and Budgets",
      content:
        "- Mastering the basics of financial statements and budgets is fundamental.\n" +
        "- Self-Reflection Points:\n" +
        "  - How do I interpret key financial documents like balance sheets and income statements?\n" +
        "  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n" +
        "  - What resources or tools could enhance my skills in financial statement analysis?",
    },
    {
      title: "Evaluating Financial Health and Performance",
      content:
        "- Assessing financial health is crucial for the sustainability of any initiative.\n" +
        "- Self-Reflection Points:\n" +
        "  - How do I determine the financial viability of a project or society event?\n" +
        "  - What metrics or indicators are useful in assessing financial performance?\n" +
        "  - How can financial data guide more sustainable decision-making processes?",
    },
    {
      title: "Risk Assessment in Financial Decision-Making",
      content:
        "- Understanding and managing financial risks is key to sound financial decision-making.\n" +
        "- Self-Reflection Points:\n" +
        "  - How do I identify potential financial risks in my projects or society activities?\n" +
        "  - What strategies do I employ to mitigate these risks?\n" +
        "  - How do I balance potential risks and rewards when making financial decisions?",
    },
  ];

  const accordionData6 = [
    {
      title: "Explore the Pillars of Sustainable Leadership",
      content:
        "A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n" +
        "Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n" +
        "Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization's identity and mission.\n\n",
    },
  ];

  const accordionData7 = [
    {
      title: "Additional Resources for Further Learning",
      content:
        "- Playing to Win: How Strategy Really Works by A.G. Lafley and Roger L. Martin, Chapter 4 – Where to Play\n" +
        "- Think Smarter by Michael Kallet, Chapter 3 – Strategic Thinking\n" +
        "- Blue Ocean Strategy by W. Chan Kim and Renée Mauborgne, Chapter 3 - Reconstruct Market Boundaries\n" +
        "- Good Strategy/Bad Strategy by Richard Rumelt, Chapter 1 – What is Strategy\n",
    },
  ];

  const cardData = [
    {
      id: 1,
      frontContent: "SWOT Analysis",
      backContent: (
        <div>
          <p>
            SWOT Analysis involves assessing an organization's internal
            strengths and weaknesses, as well as external opportunities and
            threats. Shameer uses this framework to understand LES's position,
            identify areas for improvement, and uncover opportunities for
            growth.
          </p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              Reflect on how you can apply SWOT Analysis to evaluate your
              student society’s current standing and future potential.
            </li>
            <li>
              Analyze your society’s internal capabilities and external
              environment. How do these insights inform your strategic planning?
            </li>
            <li>
              Consider how SWOT Analysis can guide your decision-making and
              strategy development in student-led initiatives.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: "PESTLE Analysis",
      backContent: (
        <div>
          <p>
            PESTLE Analysis helps in understanding the macro-environmental
            factors that can impact an organization. Shameer employs this
            framework to comprehend the broader context in which LES operates,
            ensuring that his strategies are aligned with external realities.
          </p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              Think about how PESTLE Analysis can help you grasp the larger
              societal and economic factors affecting LUMS and its societies.
            </li>
            <li>
              Evaluate how these external factors influence your society’s
              strategies and operations.
            </li>
            <li>
              Reflect on the application of PESTLE Analysis in enhancing your
              strategic thinking and societal leadership.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: "Porter's Five Forces",
      backContent: (
        <div>
          <p>
            Porter's Five Forces is a tool for analyzing an industry's
            competitive forces and structure. In the context of LES, Shameer
            utilizes this framework to understand the competitive landscape of
            entrepreneurial ventures and student initiatives.
          </p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              Consider how Porter’s Five Forces can be adapted to assess the
              competitive environment of student societies at LUMS.
            </li>
            <li>
              Analyze the dynamics of competition, the threat of new entrants,
              bargaining power of suppliers and buyers, and the threat of
              substitute products or services.
            </li>
            <li>
              Explore how this understanding can inform your strategic choices
              and help LES, or other societies, carve a niche in the university
              landscape.
            </li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];

  const questionsData2 = [
    {
      question:
        "The primary contact in a society is responsible for making final decisions on harassment cases.",
      options: ["True", "False"],
      correctAnswer: "False",
    },
    {
      question:
        "The Office of Accessibility and Inclusion (OAI) is responsible for handling cases of sexual harassment reported by societies.",
      options: ["True", "False"],
      correctAnswer: "True",
    },
    {
      question:
        "Confidentiality is a critical component of the harassment reporting process, and all reports should protect the identities of those involved.",
      options: ["True", "False"],
      correctAnswer: "True",
    },
    {
      question:
        "Harassment cases can be resolved informally within societies without involving university offices if both parties agree.",
      options: ["True", "False"],
      correctAnswer: "False",
    },
    {
      question:
        "Training on sexual harassment policies is mandatory for all society members at LUMS.",
      options: ["True", "False"],
      correctAnswer: "True",
    },
  ];

  const questionsData1 = [
    {
      question:
        "What is the first step in the reporting mechanism within LUMS societies for incidents of harassment?",
      options: [
        "Contacting the University Security",
        "Reporting directly to the Disciplinary Committee (DC)",
        "Documenting the incident with the society's designated primary contact",
        "Sending an email to the Office of Accessibility and Inclusion (OAI)",
      ],
      correctAnswer:
        "Documenting the incident with the society's designated primary contact",
      explanation:
        "The first step in addressing an incident within societies is to report it to the designated primary contact, who is trained and informed on how to handle initial reports effectively.",
    },

    {
      question:
        "Which office is primarily responsible for consulting on incidents that fall under sexual harassment at LUMS?",
      options: [
        "Co-Curricular Activities Office",
        "Office of Accessibility and Inclusion (OAI)",
        "Disciplinary Committee (DC)",
        "Executive Council of the society",
      ],
      correctAnswer: "Office of Accessibility and Inclusion (OAI)",
      explanation:
        "The Office of Accessibility and Inclusion (OAI) is primarily responsible for consulting and guiding the next steps when an incident of sexual harassment is reported.",
    },

    {
      question:
        "What role does the Executive Council play in the context of the LUMS Sexual Harassment Policy?",
      options: [
        "Directly handling and sanctioning reports of harassment",
        "Providing initial guidance and ensuring compliance with university policies",
        "Investigating reports of harassment independently",
        "Issuing final decisions on reported cases",
      ],
      correctAnswer:
        "Providing initial guidance and ensuring compliance with university policies",
      explanation:
        "The Executive Council is responsible for ensuring that all members are familiar with the policies, facilitating regular training sessions, and ensuring compliance, but they do not handle the reports directly or issue final decisions on cases.",
    },

    {
      question:
        "According to the policy, what type of training is mandatory for society members?",
      options: [
        "Training on financial management",
        "Sexual harassment policy training",
        "Event management training",
        "Leadership skills training",
      ],
      correctAnswer: "Sexual harassment policy training",
      explanation:
        "Mandatory training on the university’s sexual harassment policy is required for all society members to ensure they understand how to handle situations appropriately and are aware of the policy details.",
    },

    {
      question:
        "What should be the immediate step if a society is uncertain whether an incident qualifies as sexual harassment?",
      options: [
        "Conduct an internal vote among EC members",
        "Consult the Office of Accessibility and Inclusion (OAI)",
        "Dismiss the case due to lack of clarity",
        "Refer it to the Disciplinary Committee (DC)",
      ],
      correctAnswer: "Consult the Office of Accessibility and Inclusion (OAI)",
      explanation:
        "The OAI is the designated authority to provide clarity and guidance on harassment cases.",
    },
  ];

  const steps = [
    <section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-semibold sm:text-xl">
        Welcome to Week 10: Sexual Harassment and Sensitivity Training
      </h2>
      <p className="mt-4 text-gray-600">
        In our final week, we’ll address one of the most critical aspects of
        leadership: fostering a safe and inclusive environment. We’ll discuss
        recognizing and responding to sexual harassment and promoting
        sensitivity within your teams. By the end of this session, you’ll be
        equipped to lead with empathy and ensure that everyone feels respected
        and valued in your society.
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <Quote
            content={
              "Over 80% of employees believe that clear policies and sensitivity training are essential to creating a safe and inclusive environment."
            }
            person={""}
          />

          <div className="video-container">
            {/* <YouTube videoId={videoId} opts={opts} /> */}

            {/* <div ref={playerRef}></div> */}
            {/* <Vimeo
              video={videoId}
              autoplay
            /> */}
            <div className="flex justify-center items-center">
              <img
                className="w-1/2 mx-auto rounded-lg"
                src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1737446367/pana_rbrrrr.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h1 className="text-2xl font-bold mb-4">Introduction</h1>

      <h2 class="text-lg font-bold mb-4">Sexual Harassment</h2>
      <p class="mb-4">
        Sexual harassment, which is any unwelcome sexual advance, request for
        sexual favors or other verbal or written communication or physical
        conduct of a sexual nature, or sexually demeaning attitudes, causing
        interference with work performance or creating an intimidating, hostile
        or offensive work environment, or the attempt to punish the complainant
        for refusal to comply to such a request or is made a condition for
        employment. Sexual harassment may involve the behaviour of a person of
        either sex against a person of the opposite or same sex.
      </p>
      <div class="p-4 bg-yellow-100 border-l-4 border-yellow-400">
        <p class="italic">
          If at any point, you feel uncomfortable around someone but can’t
          pinpoint or explain why, it is worth talking about. Rest assured; your
          anonymity will be our priority. Proceed to resources.
        </p>
      </div>

      <h2 class="text-lg font-bold mb-4">Examples of Harassment</h2>
      <ul class="list-disc list-inside">
        <li class="mb-2">
          <strong>A. Uncomfortable Invitations:</strong> Inviting members for
          unofficial meetings outside campus with the allure of leadership
          positions or other society privileges.
        </li>
        <li class="mb-2">
          <strong>B. Unsolicited Favors:</strong> Senior society members
          demanding favors from junior members in exchange for roles in society
          events or activities.
        </li>
        <li class="mb-2">
          <strong>C. Rumors and Defamation:</strong> Spreading rumours or false
          information about society members to tarnish their reputation within
          the society or the larger student community.
        </li>
        <li class="mb-2">
          <strong>D. Bullying and Intimidation:</strong> Bullying or
          intimidation by senior society members towards newcomers or minority
          students.
        </li>
        <li class="mb-2">
          <strong>E. Inappropriate Comments:</strong> Making unwarranted and
          inappropriate comments about a member’s appearance or personal life
          during society meetings or events.
        </li>
        <li class="mb-2">
          <strong>F. Private Gatherings:</strong> Inviting members to private
          gatherings under the guise of society work and making inappropriate
          advances or comments.
        </li>
        <li class="mb-2">
          <strong>G. Offensive Content:</strong> Sharing or promoting offensive
          content, such as inappropriate jokes or memes, within society group
          chats or social media platforms.
        </li>
        <li class="mb-2">
          <strong>H. Gestures and Remarks:</strong> Staring, making
          inappropriate gestures, or passing suggestive remarks during society
          events or rehearsals.
        </li>
        <li class="mb-2">
          <strong>I. Personal Discussions:</strong> Discussing intimate
          experiences or relationships within the society, especially if it’s
          done to embarrass or mock someone.
        </li>
        <li class="mb-2">
          <strong>J. Derogatory Language:</strong> Using derogatory language
          that objectifies or demeans members based on their gender or
          appearance.
        </li>
        <li class="mb-2">
          <strong>K. Physical Contact:</strong> Making unwarranted physical
          contact, like deliberately bumping into someone during society events
          or activities.
        </li>
        <li class="mb-2">
          <strong>L. Mocking Attributes:</strong> Commenting on or mocking
          personal attributes of members, such as body shape, voice, or attire,
          especially with a sexual undertone.
        </li>
        <li class="mb-2">
          <strong>M. Explicit Content:</strong> Sharing or discussing explicit
          content related to a society member on society platforms or group
          chats.
        </li>
        <li class="mb-2">
          <strong>N. Financial Manipulation:</strong> Offering financial aid or
          resources to society members in return for personal favours.
        </li>
        <li class="mb-2">
          <strong>O. Inappropriate Jokes:</strong> Making inappropriate comments
          or jokes during society presentations, performances, or meetings.
        </li>
        <li class="mb-2">
          <strong>P. Prolonged Meetings:</strong> Holding prolonged private
          meetings with a society member without a clear agenda or reason.
        </li>
        <li class="mb-2">
          <strong>Q. Shaming:</strong> Publicly or privately shaming a member
          based on rumours or personal biases.
        </li>
        <li class="mb-2">
          <strong>R. Unsolicited Messages:</strong> Sending unsolicited messages
          or notes with inappropriate content or requests.
        </li>
        <li class="mb-2">
          <strong>S. Manipulation:</strong> Using one’s position in the society
          to manipulate or coerce members into unwanted situations.
        </li>
        <li class="mb-2">
          <strong>T. Fake Content:</strong> Creating or sharing fake content to
          defame or blackmail society members.
        </li>
        <li class="mb-2">
          <strong>U. Invasion of Space:</strong> Touching or making gestures
          that invade a member’s personal space without consent.
        </li>
        <li class="mb-2">
          <strong>V. Objectifying Content:</strong> Using society platforms to
          promote or share content that objectifies or demeans any gender.
        </li>
        <li class="mb-2">
          <strong>W. Forced Roles:</strong> Forcing members into roles or tasks
          against their will as a form of punishment or control.
        </li>
        <li class="mb-2">
          <strong>X. Threats:</strong> Threatening members with false evidence
          or information to gain compliance or silence.
        </li>
        <li>
          <strong>Y. Anonymous Messages:</strong> Sending anonymous messages
          that aim to defame or intimidate society members.
        </li>
      </ul>
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h1 className="text-2xl font-bold mb-4">Review Questions</h1>
      <p class="mb-4">
        By understanding what sexual harassment is, recognizing the signs,
        knowing how to report it, and regularly reviewing these guidelines, we
        can ensure a safe and respectful environment for everyone.
      </p>

      <h2 className="text-2xl mt-4">Q1: What is sexual harassment?</h2>
      <p>
        Sexual harassment is any unwelcome sexual advance, request for sexual
        favors, or other verbal or written communication or physical conduct of
        a sexual nature, or sexually demeaning attitude, causing interference
        with work performance or creating an intimidating, hostile or offensive
        work environment, or the attempt to punish the complainant for refusal
        to comply to such a request or is made a condition for employment.
        Sexual harassment may involve the behaviour of a person of either sex
        against a person of the opposite or same sex.
      </p>

      <h2 className="text-2xl mt-4">
        Q2: What should I do if I experience or witness sexual harassment?
      </h2>
      <p>
        At the time the incident is witnessed or experienced, write down what
        happened, including dates, times, and any witnesses. Report it to a
        trusted member of your student society, a CCA Officer, or directly to
        the Office of Accessibility and Inclusion (OAI). It's important to speak
        up so that the behavior can be addressed and stopped.
      </p>

      <h2 className="text-2xl mt-4">
        Q3: Will my report be kept confidential?
      </h2>
      <p>
        Yes, your identity and the details of your report will be kept
        confidential to protect your privacy and ensure your safety.
      </p>

      <h2 className="text-2xl mt-4">
        Q4: What if I'm not sure if what happened to me is sexual harassment?
      </h2>
      <p>
        If you feel uncomfortable or unsure, talk to someone you trust, such as
        a designated EC member, your society’s designated CCA Officer, or the
        OAI. They can help you understand and decide the best course of action.
      </p>

      <h2 className="text-2xl mt-4">
        Q5: Can I have someone with me when I report sexual harassment?
      </h2>
      <p>
        Yes, both the complainant and the accused reserve the right to be
        represented or accompanied by a representative, friend, or colleague
        during any inquiry process.
      </p>
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h1 className="text-2xl font-bold mb-4">Escalation Process</h1>
      <p class="mb-4">
        The Executive Council, in consultation with society members, should
        identify trusted and approachable individuals to act as the primary
        contacts for reporting incidents. Regular training sessions, in
        collaboration with the Office of Accessibility and Inclusion (OAI), will
        ensure these contacts are well-equipped to handle initial reports. It is
        the responsibility of the Executive Council to maintain an updated
        record of these designated contacts and to provide this information to
        both the Co-Curricular Activities Office (CCA) and the OAI on a regular
        basis.
      </p>

      <p class="mb-4">
        The following flowchart details the process of escalating reports which
        should be transparent and known to all society members.
      </p>

      <div className="flex justify-center mt-6">
        <img
          src="https://res.cloudinary.com/dv36gqdk4/image/upload/v1737453535/flowchart_xktpge.png"
          alt="Flowchart"
          className="w-1/3 h-auto rounded-lg"
        />
      </div>
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <div className="p-6 bg-gray-50 border border-gray-300 rounded-md">
        <h2 className="text-xl font-bold mb-4">
          Steps in the Escalation Process
        </h2>
        <div className="space-y-4">
          <div>
            <strong>Step 1 - Initial Report:</strong> Once an incident is
            reported to the designated primary contact within the society, they
            should document the details, ensuring accuracy and confidentiality.
          </div>
          <div>
            <strong>Step 2 - Preliminary Review:</strong> The primary contact
            should conduct a preliminary review of the report to determine its
            severity and the immediate actions required.
          </div>
          <div>
            <strong>Step 3 - Determination of Case Type:</strong> Based on the
            nature of the incident, it should be determined whether the case
            falls under sexual harassment or another type of conduct violation.
            If society is uncertain or requires consultation regarding the
            classification of the incident, they are encouraged to reach out to
            the Office of Accessibility and Inclusion (OAI) for guidance and
            clarity.
          </div>
          <div>
            <strong>Step 4 - Formal Investigation:</strong> Depending on the
            nature of the case, either the OAI (for sexual harassment) or the DC
            (for other conduct violations) will initiate a formal investigation.
          </div>
          <div>
            <strong>Step 5 - Decision:</strong> Based on the findings of the
            investigation, either the OAI or the DC will decide on the
            appropriate actions.
          </div>
          <div>
            <strong>Step 6 - Feedback to Parties:</strong> All stakeholders
            involved in the incident should be informed about the outcome of the
            investigation and the actions taken, ensuring transparency while
            maintaining the confidentiality of all parties involved.
          </div>
          <div>
            <strong>Step 7 - Action by Competent Authority:</strong> The
            competent authority shall impose the penalty recommended by the
            relevant authority within one (1) week of the receipt of the
            recommendation. Society must adhere to any directives or
            recommendations provided.
          </div>
          <div>
            <strong>Step 8 - Review and Reflection:</strong> Periodically, the
            Executive Council should review the escalation process in
            collaboration with CCA, OAI, and the DC to identify areas for
            improvement and ensure that it remains effective and aligned with
            university guidelines.
          </div>
        </div>

        {/* Points to Note */}
        <h2 className="text-lg font-bold mt-8">Points to Note</h2>
        <p className="mt-2">
          <strong>Documentation is necessary:</strong> All steps taken, from the
          initial report to the final decision, should be thoroughly documented
          by the relevant authority (either OAI or DC). This ensures
          accountability and provides a reference for any future incidents.
        </p>
        <p>
          <strong>Engagement with CCA:</strong> The Co-Curricular Activities
          Office (CCA) should be informed of the incident, especially if it
          involves larger society events or activities that might have broader
          implications.
        </p>

        {/* Resources and Support */}
        <h2 className="text-lg font-bold mt-8">Resources and Support</h2>
        <p className="mt-2">
          <a
            href="https://drive.google.com/file/d/1lXIkhhzLCwqY_bidnlJWlwKQfSbJlCPE/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            View Harassment Policy PDF
          </a>{" "}
          (Contains contact information and location of relevant offices)
        </p>

        {/* How to Report */}
        <h3 className="text-md font-semibold mt-6">
          How to report when you witness or experience harassment?
        </h3>
        <p className="mt-2">
          At the time the incident is witnessed or experienced, write down what
          happened, including dates, times, and any witnesses. Proceed to tell a
          designated EC member in your society, your society’s designated CCA
          Officer, or report directly to the
          <a
            href="https://drive.google.com/file/d/1lXIkhhzLCwqY_bidnlJWlwKQfSbJlCPE/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Office of Accessibility and Inclusion (OAI)
          </a>
          .
        </p>
        <p className="mt-2">
          <strong>View:</strong>{" "}
          <a
            href="https://drive.google.com/file/d/1lXIkhhzLCwqY_bidnlJWlwKQfSbJlCPE/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            Resources and Support
          </a>{" "}
          for contact information, location of offices, and how to directly
          report to the OAI.
        </p>
      </div>
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h1 className="text-2xl font-bold mb-4">Reinforcement</h1>

      <p class="mb-4">
        The following questions focus on the steps to be taken when dealing with
        sexual harassment, student leadership in policy enforcement, and
        mandatory training requirements. This will help to ensure that you are
        aware of the correct procedures and the importance of these policies in
        maintaining a safe and respectful campus environment.
      </p>

      <QuizComponent
        questionsData={questionsData1}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h1 className="text-2xl font-bold mb-4">True/False Questions: </h1>

      <QuizComponent
        questionsData={questionsData2}
        onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}
      />
    </section>,
  ];

  
const getTitle = (currentStep) => {
  switch (currentStep) {
    case 0:
      return "Introduction to Sexual Harassment and Sensitivity Training";
    case 1:
      return "Understanding Sexual Harassment";
    case 2:
      return "Review Questions";
    case 3:
      return "Escalation Process";
    case 4:
      return "Steps in the Escalation Process";
    
    case 5:
      return "Reinforcement: Understanding Reporting Mechanisms";
    case 6:
      return "True/False Knowledge Check";
    default:
      return "Training Module";
  }
};
  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              // disabled={
                              //   currentStep === 2 && (currentImageIndex<6) ||
                              //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                              //   }
                              disabled={
                                [5,6].includes(currentStep) && !isOptionSelected
                              }
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                              disabled={
                                [6].includes(currentStep) && !isOptionSelected
                              }
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Week10;
