// GalleryPage.js
import React from "react";
import ImageSlider from "./ImageSlider"; // Import the ImageSlider component

const SliderWeek7 = ({ onImageIndexChange }) => {
  const slideImages = [
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1737450071/Slide_16_9_-_118_femclt.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1737450070/Slide_16_9_-_119_wazbk7.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1737450226/Slide_16_9_-_120_1_bsggin.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1738138623/Slide_16_9_-_129_frdcmh.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1738138622/Slide_16_9_-_130_gfojlx.png",
  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };

  return (
    <div className="gallery-page">
      <ImageSlider
        className="w-full"
        onImageIndexChange={handleImageIndexChange}
        images={slideImages}
      />{" "}
      {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek7;
