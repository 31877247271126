
import React, { useState } from "react";

const QuestionnaireComponent = ({ questionsData, onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    // Proceed to the next question after a selection
    setTimeout(() => {
      handleNextQuestionOrFinish();
    }, 500); // Delay for better UX
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption(null);
    } else {
      console.log("Questionnaire Completed!");
      onComplete(true);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-4 rounded-md">
      {currentQuestion < questionsData.length ? (
        <div>
          <h2 className="text-xl text-center font-semibold mb-8">
            {questionsData[currentQuestion].question}
          </h2>
          <div className="grid grid-cols-1 gap-4 mx-auto w-8/12">
            {questionsData[currentQuestion].options.map((option, index) => (
              <div
                key={index}
                className={`${
                  selectedOption === option ? "bg-purple-200" : "bg-white"
                } p-4 rounded-2xl m-1 text-center border-2 cursor-pointer`}
                onClick={() => handleOptionSelect(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold mb-4">Questionnaire Completed!</h1>
        </div>
      )}
    </div>
  );
};

export default QuestionnaireComponent;
