import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";

import Home from "./pages/Home";

import Login from "./pages/Login";

import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import ChangePasswordForm from "./pages/ChangePasswordForm";
import UpdatePasswordPage from "./pages/UpdatePasswordPage";
import Error from "./pages/Error";
import LandingPage from "./pages/LandingPage";
import { PasteDisabledProvider } from './guided_pathways/PasteDisabled';
import SplashGP from "./guided_pathways/SplashGP";
import Weeks from "./guided_pathways/Weeks";

import Week11 from "./guided_pathways/Week11";
import Week12 from "./guided_pathways/Week12";
import Week13 from "./guided_pathways/Week13";
import Week14 from "./guided_pathways/Week14";
import Week15 from "./guided_pathways/Week15";
import EmailVerify from "./guided_pathways/EmailVerify";
import AddUserForm from "./guided_pathways/AddUserForm";
import SemiCircularMenuButton from "./guided_pathways/SemiCircularMenuButton";
// import Week6 from "./guided_pathways/Week6.";
import Form from "./guided_pathways/Form";
import Network from "./pages/Network";
import EnrolLand from "./guided_pathways/EnrolLand";
import EditPeer from "./pages/EditPeer";
// import Week5 from "./guided_pathways/Week5";
// import Week7 from "./guided_pathways/Week7";
import WeekProgress from "./pages/WeekProgress";
import WeekProgressEdit from "./pages/WeekProgressEdit";
import ChangeLogs from "./guided_pathways/ChangeLogs";
import Week1 from "./guided_pathways/Week1";
import Week2 from "./guided_pathways/Week2";
import Week3 from "./guided_pathways/Week3";
import Week4 from "./guided_pathways/Week4";
import Week8 from "./guided_pathways/Week8";
import Week9 from "./guided_pathways/Week9";
import Week10 from "./guided_pathways/Week10";
import Week5 from "./guided_pathways/Week5";
import Week6 from "./guided_pathways/Week6";
import Week7 from "./guided_pathways/Week7";
function App() {



  return (
    <Router>
      <AuthProvider>
        <div className="App">
        <PasteDisabledProvider>
          <Routes>
          <Route path="/login" element={<Login />} />
          <Route
              path="/verify"
              element={<EmailVerify />}
              exact
            />
            <Route path="/new_pass/user/2959094878/:id" element={<UpdatePasswordPage />} exact />
            <Route path="/pass_reset" element={<ChangePasswordForm />} exact />
            <Route path="/enrollform" element={<Form/>} exact /> 
             <Route path="/enroll" element={<EnrolLand/>} exact /> 
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<LandingPage/>} />
            
            <Route path="/home" element={<Home />} />
            <Route path="*" element={<Error />} />
            <Route path="/network" element={<Network />} />
            
            
            <Route path="/pathway" element={<SplashGP />} exact /> 
            
            <Route path="/weeks" element={<Weeks/>} exact /> 
            
              <Route path="/week_1" element={<Week1/>} exact /> 
              <Route path="/week_2" element={<Week2/>} exact /> 
              <Route path="/week_3" element={<Week3/>} exact /> 
              <Route path="/week_4" element={<Week4/>} exact /> 
              <Route path="/week_5" element={<Week5/>} exact /> 
              <Route path="/week_6" element={<Week6/>} exact /> 
              <Route path="/week_7" element={<Week7/>} exact /> 
              <Route path="/week_8" element={<Week8/>} exact /> 
              <Route path="/week_9" element={<Week9/>} exact /> 
              <Route path="/week_10" element={<Week10/>} exact /> 
              {/* <Route path="/week_31" element={<Week31/>} exact /> */}
              <Route path="/week_11" element={<Week11/>} exact /> 
              <Route path="/week_12" element={<Week12/>} exact /> 
              <Route path="/week_13" element={<Week13/>} exact /> 
              <Route path="/week_14" element={<Week14/>} exact /> 
              <Route path="/week_15" element={<Week15/>} exact />
            
            {/* <Route path="/cert" element={<Certificate/>} exact />  */}
            
           
            <Route path="/edit_peer/:id" element={<EditPeer/>} exact />
            <Route path="/all_progress/:id" element={<WeekProgress />} exact />
            <Route path="/edit_peer/:id" element={<EditPeer />} exact />
            <Route path="/logs" element={<ChangeLogs />} exact />
            <Route path="/add_user_form" element={<AddUserForm />} exact />
            <Route
              path="/get_single_week/:id/:weekid"
              element={<WeekProgressEdit />}
              exact
            />
            </Route>
          </Routes>
          </PasteDisabledProvider>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;