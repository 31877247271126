import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css"; // Import Video.js styles
import YouTube from "react-youtube";
import Curriculum from "./Curriculum";
import SliderWeek1 from "./SliderWeek1";
import { useLocation } from "react-router-dom";
import axios from "axios";
import NavbarBack from "../components/NavbarBack";
import { toast, Toaster } from "react-hot-toast";
import SliderWeek6 from "./SliderWeek6";
import SliderWeek6_2 from "./SliderWeek6_2";
import SliderWeek6_3 from "./SliderWeek6_3";
import SliderWeek6_4 from "./SliderWeek6_4";
import Collapse from "../components/Collapse";
import Vimeo from "@u-wave/react-vimeo";
import SliderWeek8 from "./SliderWeek8";
import CardFlip from "./CardFlip";
import Confetti from "react-confetti";
import SliderWeek8_2 from "./SliderWeek8_2";
import QuizComponent from "./QuizComponent";
import Quote from "../components/Quote";


const Week9 = () => {
  let { user } = useContext(AuthContext);
  const week_id = 9;
  const week_name = "week_8";
  const pathway_id = 1;
  // extract profile id
  const location = useLocation();
  const { server_progress } = location.state;
  let status = server_progress.status;
  let section = server_progress.section;
  let params = useParams();
  let profileID = user["user_id"];
  const [currentStep, setCurrentStep] = useState(server_progress.section || 0);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // validate the user if he is the owner of the profile
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  // initalize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loading, setLoading] = useState(true);
  const [swotSubmission, setSubmission] = useState({
    response: "",
  });
  const [assignedReview, setAssigned] = useState({
    stakeholders: "",
    choices: "",
    principles: "",
  });
  const [swotFeedback, setFeedback] = useState("");

  const fetchAssignedReview = async (user_id, week_id, week_name, section) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_assigned_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway_id,
        },
        config
      )
      .then((response) => {
        console.log(response);
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          setAssigned({
            // strengths: data.strengths,
            // weaknesses: data.weaknesses,
            // opportunities: data.opportunities,
            // threats: data.threats,
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
          setFeedback(response.data.feedback);
        }
      });
  };
  const fetchStepData = async (
    user_id,
    week_id,
    week_name,
    section,
    pathway
  ) => {
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/get_peer_submission/`,
        {
          user_id: user_id,
          week_id: week_id,
          week_name: week_name,
          section: section,
          pathway: pathway,
        },
        config
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let data = JSON.parse(response.data.content);
          toast.success("Good Job! Activity Submitted", {
            icon: "👏",
            style: {
              borderRadius: "55px",
              marginBottom: "15px",
            },
          });
          setSubmission({
            stakeholders: data.stakeholders,
            choices: data.choices,
            principles: data.principles,
          });
        }
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
      fetchStepData(profileID, week_id, week_name, 4, pathway_id);
      fetchAssignedReview(profileID, week_id, week_name, 4);
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);
  //console.log(profile);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const videoId = "eqCNw8WXtQ8"; // Extract the video ID from the YouTube URL

  const opts = {
    width: "100%",
    height: "15vh", // Set the height to 100%
    playerVars: {
      autoplay: 0,
    },
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageIndexChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleNext = () => {
    if (currentStep <= totalSteps - 1) {
      if (status === "Active") {
        let new_step = currentStep + 1;
        if (new_step > section) {
          let status = "Active";
          if (new_step === totalSteps) {
            status = "Completed";
          }
          axios
            .put(
              `${process.env.REACT_APP_API_KEY}/api/update_pathway_progress/`,
              {
                user_id: user.user_id,
                section: new_step,
                week_name: week_name,
                week_id: week_id,
                status: status,
                pathway_id,
                next_week_name: "week_9",
              },
              config
            )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }

      setCurrentStep(currentStep + 1);
      setIsOptionSelected(false);
    }
    console.log(currentStep);
  };
  const navigate = useNavigate();

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  console.log(swotSubmission);
  const handleStepSubmission = () => {
    console.log("this steo", currentStep);
    if (status === "Active" || status == "Completed") {
      if (currentStep === 5) {
        axios.post(
          `${process.env.REACT_APP_API_KEY}/api/peer_feedback/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: 4,
            content: swotSubmission,
            pathway: 1,
            feedback: swotFeedback,
            rating: 0,
          },
          config
        );
        toast.success("Good Job! Feedback Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        return;
      } else {
        axios.post(
          `${process.env.REACT_APP_API_KEY}/api/peer_submission/`,
          {
            user_id: user.user_id,
            week_name: week_name,
            week_id: week_id,
            section: currentStep,
            content: swotSubmission,
            pathway: 1,
            status: "Reviewing",
            rating: 0,
            pr_enabled: true,
            activity_name:
              "BrightTech Electronics' Struggle with Market Competition",
          },
          config
        );
        toast.success("Good Job! Activity Submitted", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      }
    }
  };

  const totalSteps = 8; // Total number of steps

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;

    if (keyCode === enter) {
      console.log("a");
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log(target.value);

      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }

    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
    checkTextareas();
  };

  const [disableNext, setDisableNext] = useState(true);
  const checkTextareas = () => {
    const textareas = document.querySelectorAll(".textarea");
    let disable = false;

    textareas.forEach((textarea) => {
      if (textarea.value.trim() === "" || textarea.value.trim() === "\u2022") {
        disable = true;
      }
    });

    setDisableNext(disable);
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setShowExplanation(true);
  };

  const handleNextQuestionOrFinish = () => {
    if (currentQuestion + 1 < questionsData.length) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption("");
      setShowExplanation(false);
    }
  };

  const questionsData = [
    {
      question: "Data always speaks for itself and requires no interpretation.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question:
        "Using a variety of data sources can help ensure a well-rounded and accurate analysis.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },
    {
      question:
        "In data analysis, complex models always provide better insights than simpler ones.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question:
        "Data analysis is only useful for making financial or quantitative decisions.",
      options: ["Truth", "Myth"],
      correctAnswer: "Myth",
    },
    {
      question:
        "Effective data analysis requires considering both the quantitative and qualitative aspects of data.",
      options: ["Truth", "Myth"],
      correctAnswer: "Truth",
    },
  ];

  const accordionData = [
    {
      title: "Demonstration",
      content:
        "Ayesha demonstrates self-awareness in her leadership role by consistently reflecting on her actions, decisions, and their impact on others. She is open to feedback and actively seeks to understand how her emotions influence her leadership style and decision-making process.",
    },
    {
      title: "Lesson",
      content:
        "A key lesson we can draw from Ayesha's journey is the importance of self-awareness in leadership. Like Ayesha, we should regularly reflect on our experiences, seek feedback, and understand how our emotions shape our leadership approach. This self-awareness allows us to make informed decisions, adapt to challenges, and foster a positive and collaborative leadership environment.",
    },
  ];

  const accordionData2 = [
    {
      title: "Focus on the Principles",
      content:
        "Consider the broader impact of your actions by framing your decisions around ethical principles rather than short-term gains. Research indicates that leaders who prioritize ethical standards over immediate results cultivate a more loyal, trusting, and high-performing team.",
    },
    {
      title: "Distinguishing Ethics from Morals",
      content:
        "Ground your leadership approach in a clear understanding of both ethics and morals. While morals often stem from individual beliefs and cultural backgrounds, ethics typically refer to the accepted standards of behavior in professional and public domains. Appreciating this distinction can enhance your ability to navigate complex situations with clarity and confidence, creating an environment where ethical considerations guide the way to sustainable success.",
    },
  ];

  const accordionData3 = [
    {
      title: "Vision for the Future",
      content:
        "A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.",
    },
    {
      title: "Ethical Foundations",
      content:
        "Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.",
    },
    {
      title: "Environmental Stewardship",
      content:
        "Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization's identity and mission.",
    },
    {
      title: "Community Enrichment",
      content:
        "Sustainable leadership involves a commitment to the well-being of the community. Leaders invest in social initiatives, support educational programs, and engage in partnerships that address social issues. Their legacy is marked by the positive impact on the community’s health, wealth, and social fabric.",
    },
  ];

  const accordionData82 = [
    {
      title: "Habits to Challenge - Vision for the Future",
      content:
        "- Narrow Analysis: Avoid limiting your view to the most obvious aspects of the problem.\n" +
        "- Overlooking Diversity: Make a conscious effort to include diverse perspectives in your analysis.\n" +
        "- Conventional Solutions: Encourage creativity and out-of-the-box thinking in your approach.\n" +
        "- Immediate Results Focus: Balance the need for quick results with the importance of sustainable solutions.",
    },
    {
      title:
        "Practical Solution Implementation (Are your solutions viable and effective within your context?)",
      content:
        "- Impractical Solutions: Assess the feasibility of your proposed solutions.\n" +
        "- Avoiding Responsibility: Promote accountability in the implementation of solutions.\n" +
        "- Preference for Familiarity: Challenge the norm and encourage new methods.\n" +
        "- Isolated Problem-Solving: Understand the interconnectedness of various issues and solutions.",
    },
    {
      title:
        "Reflective Problem-Solving Practices (Are you learning from each problem-solving experience?)",
      content:
        "- Justifying Decisions Post-Facto: Be open to re-evaluating decisions based on their outcomes.\n" +
        "- Blaming External Factors: Focus on the role of your decisions and actions in the outcomes.\n" +
        "- Ignoring Past Experiences: Use previous problem-solving instances as learning opportunities.\n" +
        "- Avoiding Difficult Discussions: Foster open dialogues about the challenges and successes of your solutions.",
    },
    {
      title: "Cultivating Effective Problem-Solving Habits",
      content:
        "- Regular Review and Reflection: Make it a habit to evaluate the impact and effectiveness of your solutions.\n" +
        "- Seeking Varied Inputs: Actively seek different perspectives before making decisions.\n" +
        "- Long-Term Oriented Solutions: Consider the broader, long-term impacts of your solutions.\n" +
        "- Transparent Decision-Making: Maintain clarity in your problem-solving process to build trust and encourage participation.",
    },
  ];

  const accordionData4 = [
    {
      title: "Developing a Structured Research Approach",
      content:
        "- Structuring your research is vital for effective information gathering.\n" +
        "- Reflect on your recent research tasks:\n" +
        "  - How do I define my research objectives?\n" +
        "  - What strategies am I using to collect and organize information?\n" +
        "  - How do I ensure the sources I use are credible and relevant?",
    },
    {
      title: "Analysing Information and Drawing Insights",
      content:
        "- The essence of research lies in analyzing information and extracting valuable insights.\n" +
        "- Assess your recent research endeavors:\n" +
        "  - How do I analyze the data and information I gather?\n" +
        "  - In what ways do I synthesize information to draw meaningful conclusions?\n" +
        "  - How do I ensure my analysis is unbiased and objective?",
    },
    {
      title: "Incorporating Diverse Perspectives and Feedback",
      content:
        "- Including diverse perspectives enhances the depth and breadth of your research.\n" +
        "- Evaluate your approach to incorporating different viewpoints:\n" +
        "  - Have I considered alternative perspectives in my research?\n" +
        "  - How do I seek and incorporate feedback to refine my research?\n" +
        "  - What steps do I take to challenge my assumptions and broaden my understanding?",
    },
  ];

  const accordionData5 = [
    {
      title: "Fundamentals of Financial Statements and Budgets",
      content:
        "- Mastering the basics of financial statements and budgets is fundamental.\n" +
        "- Self-Reflection Points:\n" +
        "  - How do I interpret key financial documents like balance sheets and income statements?\n" +
        "  - In what ways do I apply this understanding to manage budgets for society events or personal finance projects?\n" +
        "  - What resources or tools could enhance my skills in financial statement analysis?",
    },
    {
      title: "Evaluating Financial Health and Performance",
      content:
        "- Assessing financial health is crucial for the sustainability of any initiative.\n" +
        "- Self-Reflection Points:\n" +
        "  - How do I determine the financial viability of a project or society event?\n" +
        "  - What metrics or indicators are useful in assessing financial performance?\n" +
        "  - How can financial data guide more sustainable decision-making processes?",
    },
    {
      title: "Risk Assessment in Financial Decision-Making",
      content:
        "- Understanding and managing financial risks is key to sound financial decision-making.\n" +
        "- Self-Reflection Points:\n" +
        "  - How do I identify potential financial risks in my projects or society activities?\n" +
        "  - What strategies do I employ to mitigate these risks?\n" +
        "  - How do I balance potential risks and rewards when making financial decisions?",
    },
  ];

  const accordionData6 = [
    {
      title: "Explore the Pillars of Sustainable Leadership",
      content:
        "A sustainable leader foresees beyond immediate goals. They set a clear, inspiring long-term vision that guides actions and policies, anticipating future challenges and opportunities. By doing so, they ensure that the organization can adapt and thrive in an ever-changing world.\n\n" +
        "Ethical leadership is the cornerstone of trust and integrity. Sustainable leaders uphold high ethical standards, make transparent decisions, and act consistently with their values. They inspire others by leading by example and holding themselves accountable, thus fostering a culture of trust.\n\n" +
        "Leaders with a sustainable mindset prioritize the environment, recognizing that resources are finite. They innovate to reduce waste, conserve energy, and lower carbon footprints, setting new industry standards. Environmental stewardship becomes a part of the organization's identity and mission.\n\n",
    },
  ];

  const accordionData7 = [
    {
      title: "Additional Resources for Further Learning",
      content:
        "- Data-ism by Steve Lohr, Chapter 4 – The Rise of Big Data\n" +
        "- Qualitative Research from Start to Finish by Robert K. Yin, Chapter 4 – Data Analysis, Interpretation, and Presentation\n" +
        "- The Art of Thinking Clearly by Rolf Dobelli, Chapter 8 – The Confirmation Bias\n" +
        "- Case Study Research by Robert K. Yin, Chapter 5 – Analyzing Case Study Evidence\n\n",
    },
  ];

  const cardData = [
    {
      id: 1,
      frontContent: "Questioning Data Sources and Methods",
      backContent: (
        <div>
          <p>
            Critical thinking in data analysis starts with questioning the
            sources and methods.
          </p>
          <ul style={{ listStyle: "disc" }}>
            <li>How critically have I evaluated the sources of my data?</li>
            <li>
              In what ways have I ensured the methods used for data collection
              and analysis are robust and unbiased?
            </li>
            <li>
              What steps can I take to further scrutinize and validate my data
              sources and methods?
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 2,
      frontContent: "Assessing Assumptions and Biases in Data",
      backContent: (
        <div>
          <p>Assessing assumptions and biases is key to sound data analysis.</p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              How do I identify and challenge the assumptions underlying my
              data?
            </li>
            <li>Where might biases be influencing my data interpretation?</li>
            <li>
              How can I adopt practices to minimize these biases and base
              conclusions on objective analysis?
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      frontContent: "Encouraging Diverse Perspectives in Data Interpretation",
      backContent: (
        <div>
          <p>
            Diverse perspectives enrich data interpretation, offering a more
            holistic view.
          </p>
          <ul style={{ listStyle: "disc" }}>
            <li>
              Have I sought out and valued different viewpoints in interpreting
              data?
            </li>
            <li>
              In what ways can I facilitate a culture where multiple
              interpretations are considered and debated?
            </li>
            <li>
              How can I encourage team members to bring their unique insights
              into our data analysis processes?
            </li>
          </ul>
        </div>
      ),
    },
    // Add more card data as needed
  ];

  const questionsData1 = [
    {
      question: "What is a key reason to sustain engagement with stakeholders?",
      options: [
        "To keep them from working with other societies.",
        "To build long-term trust and investment in your society.",
        "To show them you only need their support for one event.",
      ],
      correctAnswer: "To build long-term trust and investment in your society.",
    },

    {
      question:
        "Which of the following actions is recommended for improving networking skills?",
      options: [
        "Wait until you have a need to reach out.",
        "Connect proactively before events.",
        "Limit contact after the event is over.",
      ],
      correctAnswer: "Connect proactively before events.",
    },

    {
      question:
        "Why is it helpful to research a stakeholder before an initial meeting?",
      options: [
        "It allows you to determine their net worth.",
        "It helps personalize your approach and establish rapport.",
        "It helps determine if they’re eligible for membership.",
      ],
      correctAnswer:
        "It helps personalize your approach and establish rapport.",
    },

    {
      question:
        "What is one benefit of practicing active listening in networking?",
      options: [
        "It lets you avoid taking notes.",
        "It helps you identify the needs and preferences of stakeholders.",
        "It reduces the time spent in meetings.",
      ],
      correctAnswer:
        "It helps you identify the needs and preferences of stakeholders.",
    },

    {
      question: "Which of the following is NOT a recommended networking tip?",
      options: [
        "Follow up regularly to build the relationship.",
        "Focus only on how stakeholders can benefit you.",
        "Invite sponsors to society events for shared experiences.",
      ],
      correctAnswer: "Focus only on how stakeholders can benefit you.",
    },
  ];

  const questionsData2 = [
    {
      question:
        "It’s important to have backup solutions prepared in case a negotiation doesn’t go as expected.",
      options: ["True", "False"],
      correctAnswer: "True",
    },

    {
      question:
        "Transparency in negotiation reduces trust and often leads to fewer agreements.",
      options: ["True", "False"],
      correctAnswer: "False",
    },

    {
      question:
        "You should always be ready to walk away from a negotiation if it compromises your society’s goals.",
      options: ["True", "False"],
      correctAnswer: "True",
    },

    {
      question:
        "A written summary of the agreement after negotiation helps ensure both parties are aligned.",
      options: ["True", "False"],
      correctAnswer: "True",
    },

    {
      question:
        "Negotiation is about persuading the other party to meet all your demands without compromise.",
      options: ["True", "False"],
      correctAnswer: "False",
    },
  ];
  const steps = [

<section className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24">
      {/* Step 1 content */}
      <h2 className="text-xl font-semibold sm:text-xl">
        Welcome to Week 9: Networking and Negotiation Skills
      </h2>
      <p className="mt-4 text-gray-600">
        Leadership isn’t just about success, it’s also about preparing for challenges. This week, you’ll learn how to identify potential risks and create strategies to manage them. Whether it’s a logistical issue or a PR crisis, we’ll equip you with tools like risk matrices and crisis communication plans to handle anything that comes your way.
      </p>
      <div className="mt-6 flex justify-center w-full h-full">
        <div
          style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
        >
          <Quote
            content={
              "Student societies that plan for potential risks are 60% more likely to host successful events without major disruptions."
            }
            person={""}
          />

          <div className="video-container">
            {/* <YouTube videoId={videoId} opts={opts} /> */}

            {/* <div ref={playerRef}></div> */}
            {/* <Vimeo
              video={videoId}
              autoplay
            /> */}
            <div className="flex justify-center items-center">
              <img
                className="w-6/12 mx-auto"
                src="https://res.cloudinary.com/dmv23kuoc/image/upload/v1737446052/amico_eqmvzy.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>,
    



    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Section 1:</b> Networking Skills for Student Leaders
      </h2>

      <section
        className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
        style={{ height: "100%" }}
      >
        <h1 className="text-2xl font-bold mb-4">
          Building Effective Networking Strategies
        </h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            1. Identify Key Stakeholders and Goals
          </h2>
          <ul className="list-disc pl-5">
            <li className="mb-1">
              Determine who your primary stakeholders are, including sponsors,
              event partners, faculty, alumni, and other society ECs.
            </li>
            <li>
              Define what you hope to achieve by connecting with each
              group—whether it’s gaining funding, logistical support, or
              mentorship opportunities.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            2. Personalize Your Approach
          </h2>
          <ul className="list-disc pl-5">
            <li className="mb-1">
              Treat each connection as unique. Before reaching out, research the
              organization or individual to understand their interests, recent
              achievements, or challenges.
            </li>
            <li>
              Use this information to personalize your introduction and express
              genuine interest.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            3. Master the Art of Introduction
          </h2>
          <ul className="list-disc pl-5">
            <li className="mb-1">
              Have a quick, impactful elevator pitch ready about your society
              and your role within it.
            </li>
            <li>
              Highlight what sets your society apart and how collaborating could
              be mutually beneficial. Be friendly, approachable, and
              enthusiastic.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            4. Follow Up and Sustain Engagement
          </h2>
          <ul className="list-disc pl-5">
            <li className="mb-1">
              Consistent follow-ups are key to maintaining connections. Send a
              thank-you message or a brief update on your society’s latest
              developments.
            </li>
            <li>
              When you foster long-term relationships, stakeholders are more
              likely to feel invested in your society’s success.
            </li>
          </ul>
        </section>
      </section>
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h1 className="text-2xl font-bold mb-4">
        Tips to Improve Networking Skills
      </h1>

      <section className="mb-6">
        <ul className="list-disc pl-5">
          <li className="mb-4">
            <span className="font-bold">Be Proactive and Reach Out Early:</span>{" "}
            Don’t wait until you need something. Reach out to potential sponsors
            or partners before an event is planned. Early connections allow for
            smoother collaborations later on.
          </li>
          <li className="mb-4">
            <span className="font-bold">Use Digital Platforms Wisely:</span>{" "}
            Join professional social media platforms like LinkedIn to connect
            with alumni and sponsors. Highlight your society’s achievements and
            activities regularly to stay visible to your network.
          </li>
          <li className="mb-4">
            <span className="font-bold">Create Shared Experiences:</span> Invite
            sponsors to events or workshops, giving them an opportunity to
            engage directly with the LUMS community. This firsthand experience
            fosters trust and helps sponsors see the tangible impact of their
            support.
          </li>
          <li>
            <span className="font-bold">Practice Active Listening:</span> When
            engaging with a stakeholder, listen carefully to their needs,
            concerns, and feedback. This attentiveness can help you address
            their interests better and create a more favorable impression.
          </li>
        </ul>
      </section>
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h1 className="text-2xl font-bold mb-4">
        Case Study: Networking Success in Student Societies
      </h1>

      <section className="mb-6">
        <p className="mb-4">
          <span className="font-bold">Scenario:</span> The LUMS Science Society
          was looking to expand its annual STEM conference by inviting an
          industry sponsor. The team identified a local tech firm interested in
          supporting educational initiatives. After introducing their vision and
          sharing past successes, the society invited the firm’s representatives
          to attend an upcoming workshop. By attending, the firm’s executives
          could see the impact of their sponsorship firsthand. This experience
          convinced them to contribute to the society’s future projects.
        </p>
      </section>

      <section className="mb-6">
        <p className="mb-4">
          <span className="font-bold">Key Takeaway:</span> Inviting potential
          sponsors to see your society’s work can create a strong connection and
          establish your society as a worthwhile investment.
        </p>
      </section>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        <b>Activity 1:</b> Assessment for section 1
      </h2>

      <QuizComponent questionsData={questionsData1} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}  />
    </section>,

      <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h1 className="text-2xl font-bold mb-4">
        Section 2: Negotiation Skills for Student Leaders
      </h1>

      <h2 className="text-xl font-bold mb-4">Key Negotiation Strategies</h2>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">
          1. Know Your Goals and Limits
        </h3>
        <p className="mb-4">
          Before entering a negotiation, define what you want to achieve, and
          also know your limits. List the must-haves, nice-to-haves, and what
          you’re willing to compromise. This clarity helps you negotiate with
          confidence.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">2. Research and Prepare</h3>
        <p className="mb-4">
          Familiarize yourself with the other party’s interests, their
          challenges, and how a partnership with your society could benefit
          them. When you can demonstrate understanding, it builds trust and
          opens doors for collaboration.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">
          3. Communicate Value Effectively
        </h3>
        <p className="mb-4">
          Highlight the tangible benefits of sponsoring your society, such as
          exposure to the student body, on-campus marketing, and social media
          reach. Provide metrics from previous events (e.g., attendance numbers,
          social media engagement) to demonstrate the return on investment they
          can expect.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">
          4. Focus on Creating Win-Win Outcomes
        </h3>
        <p className="mb-4">
          Structure the negotiation around mutual benefits. If a sponsor wants
          more exposure, consider offering additional branding at your events or
          exclusive access to student attendees, while still preserving your
          society’s core interests.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">5. Be Willing to Walk Away</h3>
        <p className="mb-4">
          Not all negotiations will end in agreement, and that’s okay. If a
          sponsor’s demands exceed your capacity, politely thank them for their
          time and leave the door open for future collaboration. Standing firm
          on key values or resources can help preserve your society’s integrity.
        </p>
      </section>
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-bold mb-4">
        Tips to Improve Negotiation Skills
      </h2>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">
          1. Stay Calm and Professional
        </h3>
        <p className="mb-4">
          Negotiations can be high-stakes, so remain composed even when faced
          with pushback. Use assertive language but avoid being confrontational.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">2. Prepare Backup Solutions</h3>
        <p className="mb-4">
          Anticipate possible demands and prepare alternatives. For example, if
          a sponsor wants a particular event format, suggest alternative
          opportunities like webinars or workshops if an in-person event isn’t
          feasible.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">
          3. Build Credibility Through Transparency
        </h3>
        <p className="mb-4">
          Be open about what your society can realistically offer. Transparency
          enhances credibility, making stakeholders more likely to trust and
          work with you.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">
          4. Summarize Agreements in Writing
        </h3>
        <p className="mb-4">
          After each negotiation, recap key points in an email to ensure
          everyone is aligned. This reinforces commitment and reduces
          misunderstandings.
        </p>
      </section>
    </section>,

    <section
      className="bg-white contMain gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">Scenario</h3>
        <p className="mb-4">
          The LUMS Debate Society aimed to secure a sponsor for an upcoming
          debate tournament. The potential sponsor, a law firm, wanted exclusive
          logo placement on all event materials. However, this conflicted with
          existing university guidelines. The society’s EC negotiated an
          alternative—exclusive branding in digital marketing campaigns and
          priority speaking time during the event, while adhering to campus
          policies. The sponsor agreed, as this solution met their branding
          needs while respecting university rules.
        </p>
      </section>

      <section className="mb-6">
        <h3 className="text-lg font-bold mb-2">Key Takeaway</h3>
        <p className="mb-4">
          In negotiations, creative alternatives that align with both parties’
          goals can lead to successful outcomes without compromising core
          requirements.
        </p>
      </section>
    </section>,

    <section
      className="bg-white contMain  gpWindow rounded-lg mt-6 p-8 my-24"
      style={{ height: "100%" }}
    >
      <h2 className="text-xl font-medium sm:text-xl">
        True/False Questions for Section 2
      </h2>

      <QuizComponent questionsData={questionsData2} onOptionSelected={(isSelected) => setIsOptionSelected(isSelected)}  />
    </section>,
  ];

  const getTitle = (currentStep) => {
    switch (currentStep) {
      case 0:
        return "Introduction";

      case 1:
        return "Networking Skills for Student Leaders";

      case 2:
        return "Tips to Improve Networking Skills";

      case 3:
        return "Case Study";

      case 4:
        return "Activity 1";

      case 5:
        return "Negotiation Skills for Student Leaders";

      case 6:
        return "Tips to Improve Negotiation Skills";

      case 7:
        return "Scenario";

      case 8:
        return "True/False Questions";


      default:
        return "Page";
    }
  };

  return (
    <div>
      <div>
        <div>
          <>
            <div class="absolute w-full bg-lilac h-96 min-h-96 md:min-h-96"></div>

            <main className="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
              <NavbarBack />
              <div className="w-full px-6 py-6 mx-auto">
                <div className="w-full bg-white rounded-full">
                  {steps[currentStep]}
                </div>

                <div className="btm-nav btm-nav-lg bg-primary shadow-lg rounded-xl w-11-12 mx-auto fixed bottom-4 mt-4">
                  <span className="text-sm hidden md:flex">
                    {getTitle(currentStep)}
                  </span>

                  <div className="flex justify-cente px-6 flex-row items-center gap-4">
                    <p className="text-sm font-medium m-0">
                      {Math.floor((currentStep / totalSteps) * 100)}%
                    </p>
                    <progress
                      className="progress bg-indigo-500 progress-success w-56"
                      value={Math.floor((currentStep / totalSteps) * 100)}
                      max="100"
                    ></progress>
                    <p className="text-sm font-medium m-0">🏆</p>
                  </div>

                  <div>
                    {(() => {
                      if (currentStep < totalSteps) {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                              disabled={currentStep < 1}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleNext}
                              // disabled={
                              //   currentStep === 2 && (currentImageIndex<6) ||
                              //   currentStep === 4 && (swotSubmission.threats === '' && swotSubmission.strengths === '' && swotSubmission.weaknesses === '' && swotSubmission.opportunities === '')
                              //   }
                              disabled={[4].includes(currentStep) && !isOptionSelected}
                            >
                              Next ›
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="join">
                            <button
                              className="btn bg-locked join-item"
                              onClick={handleBack}
                            >
                              ‹ Back
                            </button>
                            <button
                              className="btn bg-locked join-item"
                              onClick={() => navigate(-1)}
                              disabled={[8].includes(currentStep) && !isOptionSelected}
                            >
                              Finish Week ›
                            </button>
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      {/* <div className="bgGray">
      <div className="">
        <div className="flex flex-col flex-1 bgGray h-full">
          <main className="flex-1">

         
            <div className="">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 h-full mb-28">
                <div></div>

                {steps[currentStep]}

     
                <div className="fixed inset-x-0 bottom-0 mt-12">
                  <div className="relative bg-primary flex justify-between mx-auto w-full items-center text-white statusTray">
                    <p className="text-sm font-medium">
                      {getTitle(currentStep)}
                    </p>

                    <div className="flex justify-row items-center gap-4">
                      <p className="text-sm font-medium">
                        {Math.floor(((currentStep + 1) / totalSteps) * 100)}%
                      </p>
                      <progress
                        className="progress bg-indigo-500 progress-success w-56"
                        value={Math.floor(
                          ((currentStep + 1) / totalSteps) * 100
                        )}
                        max="100"
                      ></progress>
                      <p className="text-sm font-medium">🏆</p>
                    </div>

                    <div className="flex flex-row gap-2 items-center">
                      <button
                        className="btn btn-sm bg-white text-primary hover:text-white"
                        onClick={handleBack}
                        style={{
                          display: currentStep === 0 ? "none" : "block",
                        }}
                      >
                        Back
                      </button>
                   

                      <button
                        className={`btn btn-sm bg-white text-primary hover:text-white`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div> */}
    </div>
  );
};

export default Week9;
