import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import "react-calendar/dist/Calendar.css";

import { Link } from "react-router-dom";
import axios from "axios";
import { HiMiniBell, HiOutlineBell, HiBell } from "react-icons/hi2";
import MobileNav from "./MobileNav";

const NavbarBack = ({ toggleSidebar }) => {
  const [events, setEvents] = useState();

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  const [profile, setProfile] = useState();
  const userID = user["user_id"];

  let url = "";
  if (user["role"] === "Student") {
    url = "profile";
  } else if (user["role"] === "Network Admin") {
    url = "network_profile";
  }

  let role = user["role"];

  if (profile?.status == "2") {
    role = "Student";
  }

  const [page, setPage] = useState("");

  // function extractAfter3000(url) {
  //   const match = url.match(/https?:\/\/(?:pathways\.startupearly\.com|localhost:3000)\/([^/0-9]*)/);
  //   if (match && match[1]) {
  //     return match[1];
  //   }
  //   return null;
  // }

  function extractAfter3000(url) {
    const match = url?.match(
      /https?:\/\/(?:pathways\.startupearly\.com|localhost:3000)\/([^/?#]*)/
    );
    if (match && match[1]) {
      return match[1];
    }
    return null;
  }

  useEffect(() => {
    setPage(extractAfter3000(window.location?.href));
  }, []);

  let contentTitle = "";
  let contentSubtitle = "";

  if (page === "student" || page === "network") {
    contentTitle = "Dashboard";
    contentSubtitle = "Overview of activities";
  } else if (page === "challenges") {
    contentTitle = "Challenges";
    contentSubtitle = "Details of current event";
  } else if (page === "weeks") {
    contentTitle = "List of Weeks";
    contentSubtitle = "Details of current event";
  } else if (page === "pathway") {
    contentTitle = "Guided Pathway";
    contentSubtitle = "Details of current event";
  } else if (page === "week_1") {
    contentTitle = "Week 1: Introduction to Leadership and LUMS Community";
    contentSubtitle = "Details of current event";
  } else if (page === "week_2") {
    contentTitle = "Week 2: Emotional Intelligence";
    contentSubtitle = "Details of current event";
  } else if (page === "week_3") {
    contentTitle = "Week 3: SMART Goals and KPI Development";
    contentSubtitle = "Details of current event";
  } else if (page === "week_4") {
    contentTitle = "Week 4: Conflict Management and Ethical Leadership";
    contentSubtitle = "Details of current event";
  } else if (page === "week_5") {
    contentTitle = "Week 5: Building and Maintaining High-Performance Teams";
    contentSubtitle = "Details of current event";
  } else if (page === "week_6") {
    contentTitle = "Week 6: Event Planning and Execution";
    contentSubtitle = "Details of current event";
  } else if (page === "week_7") {
    contentTitle = "Week 7: Risk Management and Crisis Planning";
    contentSubtitle = "Details of current event";
  } else if (page === "week_8") {
    contentTitle = "Week 8: Marketing and Public Relations for Societies";
    contentSubtitle = "Details of current event";
  } else if (page === "week_9") {
    contentTitle = "Week 9: Networking and Negotiation Skills";
    contentSubtitle = "Details of current event";
  } else if (page === "week_10") {
    contentTitle = "Week 10: Sexual Harassment and Sensitivity Training";
    contentSubtitle = "Details of current event";
  } 
   else {
    contentTitle = "";
    contentSubtitle = "Other Name Overview";
  }

  let fullname = user["first_name"] + " " + user["last_name"];

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const accessToken = authTokens.access;

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`, config)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        setProfilePhotoUrl(data.profile_photo);
        //console.log("Profile Photo URL: ", data.profile_photo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  return (
    <div>
      <nav
        className="relative  md:flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start "
        navbar-main=""
        navbar-scroll="false"
      >
        <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
          <nav className="text-black flex flex-row items-center gap-2">
            {/* breadcrumb */}

            <Link to="/weeks" className="btn btn-ghost">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>{" "}
              Main
            </Link>
            <span>/</span>
            <h6 className="mb-0 font-bold text-black capitalize">
              {contentTitle}
            </h6>
          </nav>
          <div className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
            <div className="flex items-center md:ml-auto md:pr-4 opacity-0">
              <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease">
                <span className="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                  <i className="fas fa-search" />
                </span>
                <input
                  type="text"
                  className="hidden pl-9 text-sm focus:shadow-primary-outline ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300   bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                  placeholder="Type here..."
                />
              </div>
            </div>
            <ul
              className="flex flex-row items-center justify-end gap-2 pl-0 mb-0 list-none md-max:w-full"
              style={{ zIndex: "9999999999999999999999 !import" }}
            >
              {/* online builder   */}
              <li class="flex items-center">
                <div className="dropdown dropdown-end hidden">
                  <label
                    tabIndex={0}
                    className=" -circle bg-none border-0 text-white hvr:text-black"
                  >
                    <HiBell size={25} />
                  </label>

                  <ul
                    tabIndex={0}
                    className="dropdown-content hidden z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <li>
                      <a>Item 1</a>
                    </li>
                    <li>
                      <a>Item 2</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="flex items-center dropdown"
                style={{ zIndex: "9999999999999999999999 !important" }}
              >
                <a
                  tabIndex={0}
                  className=" border-0  m-1 flex flex-row items-center gap-2 p-0 text-sm font-semibold text-white transition-all ease-nav-brand"
                >
                  {/* <img className="w-10 h-10 rounded-full" src={`${process.env.REACT_APP_API_KEY}/api/media/profile_picture/${profile?.avatar}/`} /> */}

                  {(() => {
                    if (user["role"] === "Student") {
                      return (
                        <div>
                          {(() => {
                            if (
                              profile?.avatar ===
                              "https://img.icons8.com/stickers/344/administrator-male.png"
                            ) {
                              return (
                                <img
                                  src={`https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png`}
                                  alt="profile_image"
                                  class="w-10 h-10 rounded-full"
                                />
                              );
                            } else {
                              return (
                                <img
                                  className="w-10 h-10 rounded-full"
                                  src={profile?.avatar}
                                />
                              );
                            }
                          })()}
                        </div>
                      );
                    } else if (user["role"] === "Network Admin") {
                      return (
                        <div>
                          <img
                            src={`https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png`}
                            alt="profile_image"
                            class="w-10 h-10 rounded-full"
                          />
                        </div>
                      );
                    }
                  })()}

                  <span className=" sm:inline">
                    {(() => {
                      if (user["role"] === "Network Admin") {
                        return (
                          <div className="flex flex-row gap-2 items-center dropdown dropdown-end">
                            {profileData?.network_name}{" "}
                            <small className="badge text-xs">
                              {" "}
                              {user.role}{" "}
                            </small>
                          </div>
                        );
                      } else if (user["role"] === "Student") {
                        return (
                          <div className="flex flex-row gap-2 text-black items-center dropdown dropdown-end">
                            {profileData?.firstname} {profileData?.lastname}{" "}
                            <small className="badge text-xs">
                              {" "}
                              {user.role}{" "}
                            </small>
                          </div>
                        );
                      }
                    })()}

                    <ul
                      tabIndex={0}
                      className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                      style={{ zIndex: "9999999999999999999999 !important" }}
                    >
                      {(() => {
                        if (user["role"] === "Student") {
                          return (
                            <li className="text-black hidden">
                              <Link
                                className="p-2"
                                to={
                                  user["role"] === "Network Admin" ||
                                  user["role"] === "School Admin"
                                    ? `/admin_profile`
                                    : `/student_profile`
                                }
                              >
                                Profile
                              </Link>
                            </li>
                          );
                        }
                      })()}

                      <li
                        onClick={logoutUser}
                        to="/login"
                        className="text-red-400 p-2"
                        style={{ zIndex: "9999999999999999999999 !important" }}
                      >
                        <a>Logout</a>
                      </li>
                    </ul>
                  </span>
                </a>
              </li>
              {/* <li
                className="flex items-center pl-4 xl:hidden   border-0"
                onClick={toggleSidebar}
              >
                <a
                  href="javascript:;"
                  className="block p-0 text-sm text-white transition-all ease-nav-brand"
                >
                  <div className="w-4.5 overflow-hidden">
                    <i className="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all" />
                    <i className="ease mb-0.75 relative block h-0.5 rounded-sm bg-white transition-all" />
                    <i className="ease relative block h-0.5 rounded-sm bg-white transition-all" />
                  </div>
                </a>
              </li> */}
              <li className="flex items-center px-4 hidden">
                <a
                  href="javascript:;"
                  className="p-0 text-sm text-white transition-all ease-nav-brand"
                >
                  <i
                    fixed-plugin-button-nav=""
                    className="cursor-pointer fa fa-cog"
                  />
                  {/* fixed-plugin-button-nav  */}
                </a>
              </li>
              {/* notifications */}
              <li className="relative flex items-center pr-2 hidden">
                <p className="hidden transform-dropdown-show" />
                <a
                  href="javascript:;"
                  className="block p-0 text-sm text-white transition-all ease-nav-brand"
                  dropdown-trigger=""
                  aria-expanded="false"
                >
                  <i className="cursor-pointer fa fa-bell" />
                </a>
                <ul
                  dropdown-menu=""
                  className="text-sm transform-dropdown before:font-awesome before:leading-default before:duration-350 before:ease lg:shadow-3xl duration-250 min-w-44 before:sm:right-8 before:text-5.5 pointer-events-none absolute right-0 top-0 z-50 origin-top list-none rounded-lg border-0 border-solid border-transparent   bg-white bg-clip-padding px-2 py-4 text-left text-slate-500 opacity-0 transition-all before:absolute before:right-2 before:left-auto before:top-0 before:z-50 before:inline-block before:font-normal before:text-white before:antialiased before:transition-all before:content-['\f0d8'] sm:-mr-6 lg:absolute lg:right-0 lg:left-auto lg:mt-2 lg:block lg:cursor-pointer"
                >
                  {/* add show class on dropdown open js */}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* <MobileNav source={contentTitle} img={profile?.avatar} /> */}
    </div>
  );
};
export default NavbarBack;
