// GalleryPage.js
import React from "react";
import ImageSlider from "./ImageSlider"; // Import the ImageSlider component

const SliderWeek1 = ({ onImageIndexChange }) => {
  const slideImages = [
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1738083783/Slide_16_9_-_122_orzkne.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1738083784/Slide_16_9_-_123_kultir.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1738083783/Slide_16_9_-_124_qmy3ix.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1738083783/Slide_16_9_-_125_o6ob1u.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1738083783/Slide_16_9_-_126_ygbvcz.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1738083785/Slide_16_9_-_127_c8yaci.png",
    "https://res.cloudinary.com/dv36gqdk4/image/upload/v1738083783/Slide_16_9_-_128_lgmfqm.png",
  ];

  const handleImageIndexChange = (index) => {
    if (onImageIndexChange) {
      onImageIndexChange(index);
    }
  };

  return (
    <div className="gallery-page">
      <ImageSlider
        className="w-full"
        onImageIndexChange={handleImageIndexChange}
        images={slideImages}
      />{" "}
      {/* Integrate the ImageSlider component */}
    </div>
  );
};

export default SliderWeek1;
